import React, { useState, useEffect } from "react";
import BrandCard from "../BrandCard/BrandCard";
import "./Brands.css";

import befreeAvatar from "../../images/BrandCards/befree-avatar.png";
import befree from "../../images/BrandCards/befree.png";
import sokolovAvatar from "../../images/BrandCards/Sokolov-avatar.png";
import sokolov from "../../images/BrandCards/sokolov.png";
import diamantAvatar from "../../images/BrandCards/diamant-avatar.png";
import diamant from "../../images/BrandCards/diamant.png";

function Brands() {
  const [brandData, setBrandData] = useState([
    {
      id: 1,
      name: "SOKOLOV BEAUTY",
      image: sokolovAvatar,
      isLarge: false,
      background: sokolov,
    },
    {
      id: 2,
      name: "befree",
      image: befreeAvatar,
      isLarge: true,
      background: befree,
    },
    {
      id: 3,
      name: "Diamant",
      image: diamantAvatar,
      isLarge: false,
      background: diamant,
    },
    {
      id: 4,
      name: "befree",
      image: befreeAvatar,
      isLarge: true,
      background: befree,
    },
    {
      id: 5,
      name: "Diamant",
      image: diamantAvatar,
      isLarge: false,
      background: diamant,
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("");
      const data = await response.json();
      setBrandData(data);
    };
    fetchData();
  }, []);

  return (
    <div className="brands">
      <div className="brands__container">
        {brandData.map((brand) => (
          <BrandCard key={brand.id} brand={brand} />
        ))}
      </div>
    </div>
  );
}

export default Brands;
