import iphoneImage from "../images/cards/iphone.png";
import flowImage from "../images/cards/flow.png";
import lakeImage from "../images/cards/lake.png";
import dubaiImage from "../images/cards/dubai.png";
import flagUAEImage from "../images/cards/flag-uae.png";

import profilePhoto from "../images/Sidebar/photo-profile.png";

import dariaPhoto from "../images/friends/daria.jpg";
import oksanaPhoto from "../images/friends/oksana.jpg";
import arinaPhoto from "../images/friends/arina.jpg";
import annaPhoto from "../images/friends/anna.jpg";
import angelinaPhoto from "../images/friends/angelina.jpg";
import nazarovaPhoto from "../images/friends/nazarova.jpg";
import evgeniyPhoto from "../images/friends/evgeniy.jpg";
import alinaPhoto from "../images/friends/alina.jpg";
import nataliPhoto from "../images/friends/natali.jpg";
import alisaPhoto from "../images/friends/alisa.jpg";

import befreeIcon from "../images/BrandCards/befree-avatar.png";
import sokolovIcon from "../images/BrandCards/Sokolov-avatar.png";
import diamantIcon from "../images/BrandCards/diamant-avatar.png";
import mariinskyIcon from "../images/BrandCards/Mariinsky-avatar.png";

export const initialCards = [
  {
    id: "0QtBQljgwALe6v56",
    section: "things",
    category: "Телефон",
    tags: ["электроника", "смартфон"],
    title: "iPhone 15 Pro Max 256GB",
    description:
      "Одним из первых заметных изменений в iPhone 15 Pro Max является его титановый корпус. Этот материал обеспечивает невероятную прочность и устойчивость к внешним воздействиям, при этом оставаясь легким и приятным на ощупь. ",
    image: iphoneImage,
    link: "https://megamarket.ru/catalog/details/smartfon-apple-iphone-15-pro-max-256gb-natural-titanium-100060024518_3027/",
    price: 139808,
  },
  {
    id: "JHCyvZ1oQyEDRhfk",
    section: "places",
    category: "Кофейня",
    tags: ["Магазин кофе", "Кафе"],
    title: "Дом Флоу",
    description:
      "Кофейня «Дом Флоу» расположена в историческом здании на углу Садовой улицы и Апраксина двора. Она состоит из четырех этажей, на каждом из которых есть панорамные окна, из которых открывается прекрасный вид на окрестности.",
    image: flowImage,
    link: "https://yandex.ru/maps/-/CDfUyDy5",
    address: "Садовая улица, д. 1",
  },
  {
    id: "ay67ImHS8Npwpz7B",
    section: "activities",
    category: "Культурные мероприятия",
    tags: ["искусство", "балет", "театр"],
    title: "Лебединое озеро",
    description: `"Лебединое озеро" — это величественный балет, в котором волшебная симфония движений и классической музыки Чайковского оживляет глубокую историю о любви, предательстве и искуплении, разыгрывающуюся среди мистического озера, населённого прекрасными лебедями.`,
    image: lakeImage,
    link: "https://example.com/concert",
    date: "4 июня",
    time: "13:00",
  },
  {
    id: "c9pIVHeGjkpNGye4",
    section: "turism",
    category: "Город",
    tags: ["Отдых", "Азия"],
    title: "Дубай",
    description:
      "Дубай это второй по площади эмират в стране, но самый густонаселенный. Столица эмирата – город Дубай, у многих ассоциируется со всей страной ОАЭ. Этот независимый город-государство является самым современным и прогрессивным в Арабских Эмиратах и развивается невероятными темпами. Это крупнейший в стране деловой, финансовый, торговый и культурный центр. Кроме того Дубай – любимое место отдыха для туристов со всего мира.",
    image: dubaiImage,
    link: "https://example.com/paris",
    country: "Объединенные Арабские Эмираты",
    flag: flagUAEImage,
  },
  {
    id: "q6WuTlONnVbN9xyx",
    section: "activities",
    category: "Культурные мероприятия",
    tags: ["искусство", "балет", "театр"],
    title: "Лебединое озеро",
    description: `"Лебединое озеро" — это величественный балет, в котором волшебная симфония движений и классической музыки Чайковского оживляет глубокую историю о любви, предательстве и искуплении, разыгрывающуюся среди мистического озера, населённого прекрасными лебедями.`,
    image: lakeImage,
    link: "https://example.com/concert",
    date: "4 июня",
    time: "13:00",
  },
  {
    id: "FQCQBCwm9buPH0gP",
    section: "turism",
    category: "Город",
    tags: ["Отдых", "Азия"],
    title: "Дубай",
    description:
      "Дубай это второй по площади эмират в стране, но самый густонаселенный. Столица эмирата – город Дубай, у многих ассоциируется со всей страной ОАЭ. Этот независимый город-государство является самым современным и прогрессивным в Арабских Эмиратах и развивается невероятными темпами. Это крупнейший в стране деловой, финансовый, торговый и культурный центр. Кроме того Дубай – любимое место отдыха для туристов со всего мира.",
    image: dubaiImage,
    link: "https://example.com/paris",
    country: "Объединенные Арабские Эмираты",
    flag: flagUAEImage,
  },
  {
    id: "24p6qwpTz1dvV8Wz",
    section: "things",
    category: "Телефон",
    tags: ["электроника", "смартфон"],
    title: "iPhone 15 Pro Max 256GB",
    description: "Новый смартфон с лучшими характеристиками.",
    image: iphoneImage,
    link: "https://example.com/product",
    price: 139808,
  },
  {
    id: "y0mKeuROm7A1jn1W",
    section: "places",
    category: "Кофейня",
    tags: ["Магазин кофе", "Кафе"],
    title: "Дом Флоу",
    description:
      "Кофейня «Дом Флоу» расположена в историческом здании на углу Садовой улицы и Апраксина двора. Она состоит из четырех этажей, на каждом из которых есть панорамные окна, из которых открывается прекрасный вид на окрестности.",
    image: flowImage,
    link: "https://example.com/coffeehouse",
    address: "Садовая улица, д. 1",
  },
];

export const initialCategories = {
  things: [
    "Одежда и обувь",
    "Бытовая техника и электроника",
    "Продукты питания и напитки",
    "Мебель и предметы интерьера",
    "Средства личной гигиены и косметика",
    "Спортивные товары и инвентарь",
    "Книги, фильмы и музыка",
    "Игрушки и товары для детей",
    "Инструменты и оборудование",
    "Автомобили и мотоциклы",
    "Хобби и рукоделие",
    "Домашние животные и товары для них",
    "Сад и огород",
    "Здоровье и медицина",
    "Туризм и отдых",
  ],
  places: [
    "Кафе",
    "Рестораны",
    "Парки",
    "Музеи",
    "Театры",
    "Кинотеатры",
    "Торговые центры",
    "Библиотеки",
    "Спортивные комплексы",
    "Концертные залы",
    "Выставочные центры",
    "Ночные клубы",
    "Зоопарки",
    "Аквапарки",
    "Аттракционы",
  ],
  activities: [
    "Концерты",
    "Выставки",
    "Спорт",
    "Фестивали",
    "Курсы и тренинги",
    "Туризм",
    "Йога и медитация",
    "Мастер-классы",
    "Танцы",
    "Фотография",
    "Рисование",
    "Чтение",
    "Кулинария",
    "Рукоделие",
    "Компьютерные игры",
  ],
  turism: [
    "Города",
    "Страны",
    "Места",
    "Природные парки",
    "Исторические места",
    "Культурные достопримечательности",
    "Пляжи",
    "Горнолыжные курорты",
    "Острова",
    "Национальные парки",
    "Архитектурные памятники",
    "Религиозные места",
    "Заповедники",
    "Термальные источники",
    "Круизы",
  ],
};

export const initialUser = {
  // Такой формат хранения пользовательских данных будет на сервере
  id: "VeoDbx6cbiYvmcrh",
  userInfo: {
    name: "Василий",
    surname: "Лавров",
    mail: "thewargas@mail.ru",
    phone: "8 (911) 912-88-77",
    birth: "23.12.2001",
    about: "Разработчик",
    image: profilePhoto,
  },
  privacy: {
    infoPolicy: "Все пользователи",
    wishsPolicy: "Только мои друзья",
  },
  password: "123123",
  general: {
    language: "Русский",
    city: "Санкт-Петербург",
    theme: "Светлая",
    path: "genidoto",
  },
};

export const initialUserFriends = [
  {
    id: "l0jOMrdELrDHMr4e",
    name: "Дарья",
    surname: "Чумакова",
    image: dariaPhoto,
  },
  {
    id: "k9H8jsn3Kd7GZr8Q",
    name: "Оксана",
    surname: "Жданова",
    image: oksanaPhoto,
  },
  {
    id: "p7J2dsf4Jd9Rb5FZ",
    name: "Арина",
    surname: "Ермолина",
    image: arinaPhoto,
  },
  {
    id: "r4M5asf6Lh2Ng8JH",
    name: "Анна",
    surname: "Смирнова",
    image: annaPhoto,
  },
  {
    id: "m3L9jsd8Kf4Pt3Rd",
    name: "Ангелина",
    surname: "Парова",
    image: angelinaPhoto,
  },
  {
    id: "f2S7dhj2Ld6Nt7Zv",
    name: "Дарья",
    surname: "Назарова",
    image: nazarovaPhoto,
  },
  {
    id: "b1F6afg3Jk8Mf5Xd",
    name: "Евгений",
    surname: "Новиков",
    image: evgeniyPhoto,
  },
  {
    id: "q8T4kjl4Kd3Ng6Wt",
    name: "Алина",
    surname: "Широкова",
    image: alinaPhoto,
  },
  {
    id: "z3P2jhk6Lh5Rt9Yb",
    name: "Наталья",
    surname: "Ренкова",
    image: nataliPhoto,
  },
  {
    id: "d7B8lsl2Jd4Pt2Hc",
    name: "Алиса",
    surname: "Миронова",
    image: alisaPhoto,
  },
];

export const initialUserSubs = [
  {
    id: "KfTboJAYwXogDJTq",
    name: "befree",
    description: "Магазин мужской женской одежды",
    image: befreeIcon,
    path: "befree",
  },
  {
    id: "IKf8JRvFzm55Nged",
    name: "SOKOLOV BEAUTY",
    description: "Ювелирная сеть магазинов",
    image: sokolovIcon,
    path: "sokolov",
  },
  {
    id: "e6I3wBwSa96GqSov",
    name: "DIAMANT",
    description: "Эксклюзивные ювелирные украшения",
    image: diamantIcon,
    path: "diamant",
  },
  {
    id: "3RhgqbYBkwwDABlQ",
    name: "Мариинский театр",
    description: "Ведущий музыкальный театр России",
    image: mariinskyIcon,
    path: "mariinsky",
  },
];

export const initialUserLists = {
  things: [
    {
      id: "mIT1igkUAyh6puk3",
      name: "Расходники",
    },
    {
      id: "a2D4k3UFhs7y7nLg",
      name: "Книги",
    },
    {
      id: "b8Y3h4KJLf9o2mZq",
      name: "Хоккей",
    },
    {
      id: "j9G4i5NOKx1t3cWp",
      name: "Для путешествий",
    },
    {
      id: "e6H5j7PMQy8u4nRv",
      name: "Электроника",
    },
    {
      id: "q7J6k8RNSt5v1oUx",
      name: "Одежда",
    },
    {
      id: "u8K9l0TOUw2x3pVy",
      name: "Для учёбы",
    },
  ],
  places: [
    {
      id: "Искусство",
      name: "Paris",
    },
    {
      id: "o2M3n4XQYz9v1bBc",
      name: "Кофейни",
    },
    {
      id: "p3N4o5YRZx2c3dDd",
      name: "Парки",
    },
    {
      id: "q4O5p6ZSAw4e5fEe",
      name: "Музеи",
    },
    {
      id: "r5P6q7BTBu7g6hFf",
      name: "Рестораны",
    },
    {
      id: "s6Q7r8CUCv8i7jGg",
      name: "Развлечения",
    },
  ],
  activities: [
    {
      id: "u8S9t0EXFx2l9mIi",
      name: "Театры",
    },
    {
      id: "v9T0u1FYGy3m0nJj",
      name: "Матчи",
    },
    {
      id: "w0U1v2GZHz4n1oKk",
      name: "Музеи",
    },
    {
      id: "x1V2w3HZIi5o2pLl",
      name: "Соревнования",
    },
    {
      id: "y2W3x4JZJj6p3qMm",
      name: "Развлечения",
    },
  ],
  turism: [
    {
      id: "z3X4y5KZKk7q4rNn",
      name: "Декабрь",
    },
    {
      id: "a4Y5z6LZLl8r5sOo",
      name: "По России",
    },
    {
      id: "b5Z6a7MZMm9s6tPp",
      name: "Лето",
    },
    {
      id: "c6A7b8NZNn0t7uQq",
      name: "Зарубеж",
    },
    {
      id: "d7B8c9OZOo1u8vRr",
      name: "Октябрь",
    },
  ],
};

export const initialUserWishs = [
  {
    id: "gAaDf3u5CXalNzhB",
    section: "things",
    category: "Телефон",
    list: {
      id: "e6H5j7PMQy8u4nRv",
      name: "Электроника",
    },
    title: "iPhone 15 Pro Max 256GB",
    description:
      "Одним из первых заметных изменений в iPhone 15 Pro Max является его титановый корпус. Этот материал обеспечивает невероятную прочность и устойчивость к внешним воздействиям, при этом оставаясь легким и приятным на ощупь. ",
    image: iphoneImage,
    link: "https://megamarket.ru/catalog/details/smartfon-apple-iphone-15-pro-max-256gb-natural-titanium-100060024518_3027/",
    price: 139808,
    secret: false,
  },
  {
    id: "sgXnPIcYN0h93M5P",
    section: "places",
    category: "Кофейня",
    list: {
      id: "o2M3n4XQYz9v1bBc",
      name: "Кофейни",
    },
    title: "Дом Флоу",
    description:
      "Кофейня «Дом Флоу» расположена в историческом здании на углу Садовой улицы и Апраксина двора. Она состоит из четырех этажей, на каждом из которых есть панорамные окна, из которых открывается прекрасный вид на окрестности.",
    image: flowImage,
    link: "https://yandex.ru/maps/-/CDfUyDy5",
    address: "Садовая улица, д. 1",
  },
  {
    id: "2kmQ4vLqRLH72i3N",
    section: "activities",
    category: "Культурные мероприятия",
    list: {
      id: "u8S9t0EXFx2l9mIi",
      name: "Театры",
    },
    title: "Лебединое озеро",
    description: `"Лебединое озеро" — это величественный балет, в котором волшебная симфония движений и классической музыки Чайковского оживляет глубокую историю о любви, предательстве и искуплении, разыгрывающуюся среди мистического озера, населённого прекрасными лебедями.`,
    image: lakeImage,
    link: "https://example.com/concert",
    date: "4 июня",
    time: "13:00",
  },
  {
    id: "1IdgBAV5cTy7BlN3",
    section: "turism",
    category: "Город",
    list: {
      id: "b5Z6a7MZMm9s6tPp",
      name: "Лето",
    },
    title: "Дубай",
    description:
      "Дубай это второй по площади эмират в стране, но самый густонаселенный. Столица эмирата – город Дубай, у многих ассоциируется со всей страной ОАЭ. Этот независимый город-государство является самым современным и прогрессивным в Арабских Эмиратах и развивается невероятными темпами. Это крупнейший в стране деловой, финансовый, торговый и культурный центр. Кроме того Дубай – любимое место отдыха для туристов со всего мира.",
    image: dubaiImage,
    link: "https://example.com/paris",
    country: "Объединенные Арабские Эмираты",
    flag: flagUAEImage,
  },
  {
    id: "DRK7PDMKlhJNBU58",
    section: "activities",
    category: "Культурные мероприятия",
    list: {
      id: "u8S9t0EXFx2l9mIi",
      name: "Театры",
    },
    title: "Лебединое озеро",
    description: `"Лебединое озеро" — это величественный балет, в котором волшебная симфония движений и классической музыки Чайковского оживляет глубокую историю о любви, предательстве и искуплении, разыгрывающуюся среди мистического озера, населённого прекрасными лебедями.`,
    image: lakeImage,
    link: "https://example.com/concert",
    date: "4 июня",
    time: "13:00",
  },
  {
    id: "VnrcG1RbQp1R3nEi",
    section: "turism",
    category: "Город",
    list: {
      id: "b5Z6a7MZMm9s6tPp",
      name: "Лето",
    },
    title: "Дубай",
    description:
      "Дубай это второй по площади эмират в стране, но самый густонаселенный. Столица эмирата – город Дубай, у многих ассоциируется со всей страной ОАЭ. Этот независимый город-государство является самым современным и прогрессивным в Арабских Эмиратах и развивается невероятными темпами. Это крупнейший в стране деловой, финансовый, торговый и культурный центр. Кроме того Дубай – любимое место отдыха для туристов со всего мира.",
    image: dubaiImage,
    link: "https://example.com/paris",
    country: "Объединенные Арабские Эмираты",
    flag: flagUAEImage,
  },
  {
    id: "DcXdYLsidPZCNhtM",
    section: "things",
    category: "Телефон",
    list: {
      id: "e6H5j7PMQy8u4nRv",
      name: "Электроника",
    },
    title: "iPhone 15 Pro Max 256GB",
    description:
      "Одним из первых заметных изменений в iPhone 15 Pro Max является его титановый корпус. Этот материал обеспечивает невероятную прочность и устойчивость к внешним воздействиям, при этом оставаясь легким и приятным на ощупь. ",
    image: iphoneImage,
    link: "https://megamarket.ru/catalog/details/smartfon-apple-iphone-15-pro-max-256gb-natural-titanium-100060024518_3027/",
    price: 139808,
    secret: false,
  },
  {
    id: "ETOenWlkwqkWbCH5",
    section: "places",
    category: "Кофейня",
    list: {
      id: "o2M3n4XQYz9v1bBc",
      name: "Кофейни",
    },
    title: "Дом Флоу",
    description:
      "Кофейня «Дом Флоу» расположена в историческом здании на углу Садовой улицы и Апраксина двора. Она состоит из четырех этажей, на каждом из которых есть панорамные окна, из которых открывается прекрасный вид на окрестности.",
    image: flowImage,
    link: "https://yandex.ru/maps/-/CDfUyDy5",
    address: "Садовая улица, д. 1",
  },
];
