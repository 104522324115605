import { useState, useRef, useEffect } from "react";

const useDropdown = () => {
  const [dropdownState, setDropdownState] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setDropdownState(!dropdownState);
  };

  const handleSelect = (option) => {
    setDropdownState(false);
    return option;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownState(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return { dropdownState, handleToggle, handleSelect, dropdownRef };
};

export default useDropdown;
