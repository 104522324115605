import "./Main.css";
import React from "react";
import Tips from "../Tips/Tips";
import CardList from "../CardList/CardList";

function Main({ onClickCard, cards, categories, toggleSidebar }) {
  return (
    <main className="main">
      <Tips toggleSidebar={toggleSidebar} />
      <CardList
        onClickCard={onClickCard}
        cards={cards}
        categories={categories}
      />
    </main>
  );
}

export default Main;
