import CardList from "../CardList/CardList";
import Social from "../Social/Social";
import UserNav from "../UserNav/UserNav";
import UserPanel from "../UserPanel/UserPanel";
import "./Profile.css";
import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import WishNav from "../WishNav/WishNav";

import sidebarIcon from "../../images/Sidebar/Close-button.svg";

function Profile({
  currentUser,
  currentUserFriends,
  currentUserSubs,
  currentUserLists,
  currentUserWishs,
  onClickCard,
  categories,
  setCardPopupOpen,
  setSelectedCard,
  handleAddClick,
  toggleSidebar,
}) {
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [user, setUser] = useState(null);
  const [friends, setFriends] = useState([]);
  const [subs, setSubs] = useState([]);
  const [lists, setLists] = useState({});
  const [wishs, setWishs] = useState([]);
  const { id } = useParams();

  const { pathname, search } = useLocation();
  const noCardListPaths = ["/friends", "/subscriptions"];

  useEffect(() => {
    if (!currentUser || currentUser.id !== id) {
      setIsCurrentUser(false);

      Promise.all([
        fetch(`https://jelaem.ru/api/users/${id}`).then((response) =>
          response.json()
        ),
        fetch(`https://jelaem.ru/api/users/${id}/friends`).then((response) =>
          response.json()
        ),
        fetch(`https://jelaem.ru/api/users/${id}/subscriptions`).then(
          (response) => response.json()
        ),
        fetch(`https://jelaem.ru/api/users/${id}/lists`).then((response) =>
          response.json()
        ),
        fetch(`https://jelaem.ru/api/users/${id}/wishs`).then((response) =>
          response.json()
        ),
      ])
        .then(([userData, friendsData, subsData, listsData, wishsData]) => {
          setUser(userData);
          setFriends(friendsData);
          setSubs(subsData);
          setLists(listsData);
          setWishs(wishsData);
        })
        .catch((error) => {
          console.error("Ошибка при загрузке данных пользователя:", error);
        });
    } else {
      setIsCurrentUser(true);
      setUser(currentUser);
      setFriends(currentUserFriends);
      setSubs(currentUserSubs);
      setLists(currentUserLists);
      setWishs(currentUserWishs);
    }
  }, [
    id,
    currentUser,
    currentUserFriends,
    currentUserSubs,
    currentUserLists,
    currentUserWishs,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const cardId = searchParams.get("w");
    if (cardId) {
      const card = wishs.find((c) => `card${c.id}` === cardId);
      if (card) {
        setSelectedCard(card);
        setCardPopupOpen(true);
      }
    }
  }, [search, wishs]);

  if (user === null) {
    return <div className="profile">Загрузка...</div>;
  }

  const isNoCardListPath = noCardListPaths.some((noCardPath) =>
    pathname.endsWith(noCardPath)
  );

  return (
    <div className="profile">
      <button className="sidebar-button" onClick={toggleSidebar}>
        <img
          src={sidebarIcon}
          className="sidebar-button__image"
          alt="Иконка сайдбара"
        />
      </button>
      <UserPanel user={user} />
      <Routes>
        <Route
          path="/"
          element={<UserNav wishs={wishs} friends={friends} subs={subs} />}
        />
        <Route
          path="/friends"
          element={<Social persons={friends} title="Друзья" />}
        />
        <Route
          path="/subscriptions"
          element={<Social persons={subs} title="Подписки" />}
        />
        <Route
          path="/things"
          element={
            <WishNav
              wishs={wishs}
              type="things"
              lists={lists.things || []}
              handleAddClick={handleAddClick}
            />
          }
        />
        <Route
          path="/places"
          element={
            <WishNav
              wishs={wishs}
              type="places"
              lists={lists.places || []}
              handleAddClick={handleAddClick}
            />
          }
        />
        <Route
          path="/activities"
          element={
            <WishNav
              wishs={wishs}
              type="activities"
              lists={lists.activities || []}
              handleAddClick={handleAddClick}
            />
          }
        />
        <Route
          path="/travel"
          element={
            <WishNav
              wishs={wishs}
              type="turism"
              lists={lists.turism || []}
              handleAddClick={handleAddClick}
            />
          }
        />
      </Routes>
      {!isNoCardListPath && (
        <CardList
          onClickCard={onClickCard}
          cards={wishs}
          categories={categories}
        />
      )}
    </div>
  );
}

export default Profile;
