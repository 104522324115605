import React, { useRef } from "react";
import ErrorImage from "../../images/input/input-error.svg";
import eye from "../../images/input/eye.svg";
import eyeHidden from "../../images/input/eye-hidden.svg";

const InputWithError = ({
  name,
  value,
  onChange,
  onKeyDown,
  placeholder,
  pattern,
  isError,
  messageError,
  isInputsDisabled,
  type = "text",
  onEyeClick,
  isEyeVisible,
  isTextarea = false,
}) => {
  const inputRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    inputRef.current.focus();
  };

  return (
    <div
      className={`inputs-container ${
        isTextarea ? "inputs-container_type_big" : ""
      }`}
    >
      <p
        className={`input__title ${value ? "input__title_active" : ""} ${
          isError ? "input__title_type_error" : ""
        }`}
      >
        {placeholder}
      </p>
      <div
        className={`input-container  ${
          isError ? `input-container_type_error` : ""
        } ${isTextarea ? `input-container_type_big` : ""}`}
      >
        {isTextarea ? (
          <textarea
            className={`input input_type_big ${
              isError ? `input_type_error` : ""
            }`}
            name={name}
            ref={inputRef}
            value={value || ""}
            onKeyDown={onKeyDown}
            onChange={onChange}
            placeholder={placeholder}
            pattern={pattern}
            disabled={isInputsDisabled}
            required
          />
        ) : (
          <input
            className={`input ${isError ? `input_type_error` : ""}`}
            name={name}
            ref={inputRef}
            type={type}
            value={value || ""}
            onKeyDown={onKeyDown}
            onChange={onChange}
            placeholder={placeholder}
            pattern={pattern}
            disabled={isInputsDisabled}
            required
          />
        )}
        {onEyeClick && !isTextarea && (
          <img
            className="input__image"
            onClick={onEyeClick}
            src={isEyeVisible ? eye : eyeHidden}
            onMouseDown={handleMouseDown}
            alt={isEyeVisible ? "Пароль показан" : "Пароль скрыт"}
          />
        )}
      </div>
      <span className={`input-error ${isError ? "input-error_active" : ""}`}>
        <img
          className="input-error__image"
          src={ErrorImage}
          alt="Восклицательный знак"
        />
        {isError &&
          (messageError === `Введите данные в указанном формате.`
            ? `Неверный формат.`
            : messageError)}
      </span>
    </div>
  );
};

export default InputWithError;
