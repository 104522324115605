import "./AuthForm.css";
import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useFormsValidation from "../../hooks/useFormsValidation";
import Welcome from "../Welcome/Welcome";
import ErrorImage from "../../images/input/input-error.svg";
import eye from "../../images/input/eye.svg";
import eyeHidden from "../../images/input/eye-hidden.svg";
import vkIcon from "../../images/Button/vk.svg";

import rect from "../../images/AuthForm/Left-rect.svg";
import rect2 from "../../images/AuthForm/left-rect2.svg";
import rectRight from "../../images/AuthForm/right-rect.svg";
import rectRight2 from "../../images/AuthForm/right-rect2.svg";

function AuthForm({
  title,
  subtitle,
  buttonText,
  text,
  link,
  textPath,
  onSubmit,
  apiError,
  isInputsDisabled,
}) {
  const { handleChangeInput, inputs, isError, messageError, isValidity } =
    useFormsValidation({});

  const formRef = useRef();

  const [isVisible, setVisible] = useState(false);

  const { pathname } = useLocation();

  const inputRef = useRef(null);

  const [isReset, setReset] = useState(false);

  const [isAgree, setAgree] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(inputs);
  };

  function handleClickEye() {
    setVisible(!isVisible);
  }

  function handleMoseDownEye(e) {
    e.preventDefault();
    inputRef.current.focus();
  }

  const handleChangeAgree = () => {
    setAgree(!isAgree);
  };

  return (
    <div className="auth">
      <Welcome />
      <img src={rect} className="auth__left-rect" alt="Абстрактный квадрат" />
      <img src={rect2} className="auth__left-rect" alt="Абстрактный квадрат" />
      <img
        src={rectRight}
        className="auth__right-rect"
        alt="Абстрактный квадрат"
      />
      <img
        src={rectRight2}
        className="auth__right-rect"
        alt="Абстрактный квадрат"
      />
      <form
        className="auth__form"
        name="auth-form"
        ref={formRef}
        onSubmit={handleSubmit}
        noValidate
      >
        <h2 className="auth__title">{title}</h2>
        <p className="auth__text">{subtitle}</p>
        <div className="auth__inputs">
          {pathname === "/signup" && (
            <div className="auth__inputs-container">
              <div className="inputs-container">
                <p
                  className={`input__title ${
                    inputs.name ? "input__title_active" : ""
                  } ${isError.name ? "input__title_type_error" : ""}`}
                >
                  Имя
                </p>
                <div
                  className={`input-container ${
                    isError.name ? `input-container_type_error` : ""
                  }`}
                >
                  <input
                    className={`input ${
                      isError.name ? `input_type_error` : ""
                    }`}
                    name="name"
                    type="text"
                    value={inputs.name || ""}
                    onChange={handleChangeInput}
                    placeholder="Имя"
                    pattern="^[\-\sa-zA-Zа-яА-Я]*$"
                    disabled={isInputsDisabled}
                    required
                  />
                </div>
                <span
                  className={`input-error ${
                    isError.name ? "input-error_active" : ""
                  }`}
                >
                  <img
                    className="input-error__image"
                    src={ErrorImage}
                    alt="Восклицательный знак"
                  />
                  {isError.name &&
                    (messageError.name === `Введите данные в указанном формате.`
                      ? `Неверный формат.`
                      : messageError.name)}
                </span>
              </div>
              <div className="inputs-container">
                <p
                  className={`input__title ${
                    inputs.surname ? "input__title_active" : ""
                  } ${isError.surname ? "input__title_type_error" : ""}`}
                >
                  Фамилия
                </p>
                <div
                  className={`input-container ${
                    isError.surname ? `input-container_type_error` : ""
                  }`}
                >
                  <input
                    className={`input ${
                      isError.surname ? `input_type_error` : ""
                    }`}
                    name="surname"
                    type="text"
                    value={inputs.surname || ""}
                    onChange={handleChangeInput}
                    placeholder="Фамилия"
                    pattern="^[\-\sa-zA-Zа-яА-Я]*$"
                    disabled={isInputsDisabled}
                    required
                  />
                </div>
                <span
                  className={`input-error ${
                    isError.surname ? "input-error_active" : ""
                  }`}
                >
                  <img
                    className="input-error__image"
                    src={ErrorImage}
                    alt="Восклицательный знак"
                  />
                  {isError.surname &&
                    (messageError.surname ===
                    `Введите данные в указанном формате.`
                      ? `Неверный формат.`
                      : messageError.surname)}
                </span>
              </div>
            </div>
          )}
          <div className="inputs-container">
            <p
              className={`input__title ${
                inputs.email ? "input__title_active" : ""
              } ${isError.email ? "input__title_type_error" : ""}`}
            >
              Почта
            </p>
            <div
              className={`input-container ${
                isError.email ? `input-container_type_error` : ""
              }`}
            >
              <input
                className={`input ${isError.email ? `input_type_error` : ""}`}
                name="email"
                type="text"
                value={inputs.email || ""}
                onChange={handleChangeInput}
                placeholder="Почта"
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                disabled={isInputsDisabled}
                required
              />
            </div>
            <span
              className={`input-error ${
                isError.email ? "input-error_active" : ""
              }`}
            >
              <img
                className="input-error__image"
                src={ErrorImage}
                alt="Восклицательный знак"
              />
              {isError.email &&
                (messageError.email === `Введите данные в указанном формате.`
                  ? `Введите данные в указанном формате.`
                  : messageError.email)}
            </span>
          </div>
          <div className="inputs-container">
            <p
              className={`input__title ${
                inputs.password ? "input__title_active" : ""
              } ${isError.password ? "input__title_type_error" : ""}`}
            >
              Пароль
            </p>
            <div
              className={`input-container ${
                isError.password ? `input-container_type_error` : ""
              }`}
            >
              <input
                className={`input ${
                  isError.password ? `input_type_error` : ""
                }`}
                ref={inputRef}
                name="password"
                type={isVisible ? "text" : "password"}
                value={inputs.password || ""}
                onChange={handleChangeInput}
                placeholder="Пароль"
                pattern="^(?=.*[\p{Lu}])(?=.*\d).{3,}$"
                disabled={isInputsDisabled}
                required
              />
              <img
                className={`input__image ${
                  isError.password ? `input__image_type_error` : ""
                }`}
                onClick={handleClickEye}
                onMouseDown={handleMoseDownEye}
                src={!isVisible ? eye : eyeHidden}
                alt={isVisible ? "Пароль показан" : "Пароль скрыт"}
              />
            </div>
            <span
              className={`input-error ${
                isError.password ? "input-error_active" : ""
              }`}
            >
              <img
                className="input-error__image"
                src={ErrorImage}
                alt="Восклицательный знак"
              />
              {isError.password &&
                (messageError.password === `Введите данные в указанном формате.`
                  ? `Должно быть мин. 3 символа, 1 заглавная буква и 1 цифра`
                  : messageError.password)}
            </span>
          </div>
        </div>
        {pathname === "/signup" && (
          <label className="checkbox auth__checkbox">
            <input
              key={!isReset ? "resetAgree" : "Agree"}
              type="checkbox"
              name="agree"
              value="Agree"
              className="checkbox__input"
              onChange={() => handleChangeAgree()}
            />
            <span className="checkbox__button"></span>
            <div>
              {"Нажимая кнопку, вы принимаете нашу"}{" "}
              <a href="/policy" target="__blank" className="checkbox__policy">
                {"Политику конфиденциальности."}
              </a>
            </div>
          </label>
        )}
        {apiError && <p className="auth__api-error">{apiError}</p>}
        <button
          className={`button auth__submit-button ${
            !isValidity || (!isAgree && pathname === "/signup")
              ? `button_type_disabled`
              : ""
          }`}
          type="submit"
          disabled={
            !isValidity ||
            isInputsDisabled ||
            (!isAgree && pathname === "/signup")
          }
        >
          {buttonText}
        </button>
        {pathname === "/signin" && (
          <>
            <div className="auth__or">
              <div className="auth__line"></div>
              <p className="auth__or-title">или</p>
              <div className="auth__line"></div>
            </div>
            <button className={`button auth__button`} type="button">
              Зайти через
              <img
                className="auth__button-image"
                src={vkIcon}
                alt="Иконка ВКонтакте"
              />
            </button>
          </>
        )}
        <p className="auth__link-text">
          {text}
          <Link to={textPath} className="auth__link">
            {" "}
            {link}
          </Link>
        </p>
      </form>
    </div>
  );
}

export default AuthForm;
