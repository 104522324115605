import "./CardPopup.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../images/Popup/close-icon.svg";
import shareIcon from "../../images/Popup/share.svg";
import copyIcon from "../../images/Popup/copy.svg";
import basketIcon from "../../images/Popup/buy.svg";
import listIcon from "../../images/Popup/list.svg";

import thingsIcon from "../../images/wishs/things.svg";
import placesIcon from "../../images/wishs/places.svg";
import activitiesIcon from "../../images/wishs/activities.svg";
import turismIcon from "../../images/wishs/travel.svg";

import yandexIcon from "../../images/Popup/yandex-market.png";
import megaIcon from "../../images/Popup/mega-market.png";
import ozonIcon from "../../images/Popup/ozon.png";
import wbIcon from "../../images/Popup/wb.png";

import searchIcon from "../../images/Popup/search.svg";

import useWindowSize from "../../hooks/useWindowSize";

function CardPopup({ onOverlay, isOpen, card, onClose }) {
  const [mounted, setMounted] = useState(false);
  const [activeTab, setActiveTab] = useState("photo");
  const [copyStatus, setCopyStatus] = useState({});
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  const size = useWindowSize();

  const [shops, setShops] = useState([
    {
      title: "Яндекс Маркет",
      image: yandexIcon,
      link: "https://market.yandex.ru/search?text=",
    },
    {
      title: "Мегамаркет",
      image: megaIcon,
      link: "https://sbermegamarket.ru/catalog/?q=",
    },
    {
      title: "Ozon",
      image: ozonIcon,
      link: "https://www.ozon.ru/search/?text=",
    },
    {
      title: "Wildberries",
      image: wbIcon,
      link: "https://www.wildberries.ru/catalog/0/search.aspx?search=",
    },
  ]);

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
      navigate(`?w=card${card.id}`, { replace: true });
      loadMap();
    } else {
      if (mapInstance.current) {
        mapInstance.current.destroy();
        mapInstance.current = null;
      }
      setActiveTab("photo");
    }
  }, [isOpen, card.id, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("w") === `card${card.id}`) {
      onOverlay();
    }
  }, [card.id, onOverlay]);

  const handleShare = () => {
    handleCopy(window.location.href, "share");
  };

  const handleCopy = (text, key) => {
    // Проверяем, доступен ли navigator.clipboard
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopyStatus((prev) => ({ ...prev, [key]: true }));
          setTimeout(() => {
            setCopyStatus((prev) => ({ ...prev, [key]: false }));
          }, 1000);
        })
        .catch(() => console.error("Ошибка копирования"));
    } else {
      // Резервный метод копирования для браузеров, где navigator.clipboard недоступен
      fallbackCopyTextToClipboard(text, key);
    }
  };

  const fallbackCopyTextToClipboard = (text, key) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "успешно" : "неуспешно";
      console.log("Fallback: Копирование текста было " + msg);
      setCopyStatus((prev) => ({ ...prev, [key]: true }));
      setTimeout(() => {
        setCopyStatus((prev) => ({ ...prev, [key]: false }));
      }, 1000);
    } catch (err) {
      console.error("Fallback: Не удалось скопировать текст", err);
    }

    document.body.removeChild(textArea);
  };

  const loadMap = () => {
    if (mapRef.current && !window.ymaps) {
      const script = document.createElement("script");
      script.src = `https://api-maps.yandex.ru/2.1/?apikey=14df84f5-0d44-4df5-b735-893e63f298a1&lang=ru_RU`;
      script.async = true;
      script.onload = () => {
        window.ymaps.ready(init);
      };
      document.body.appendChild(script);
    } else if (window.ymaps) {
      init();
    }
  };

  const init = () => {
    if (mapRef.current && !mapInstance.current) {
      mapInstance.current = new window.ymaps.Map(mapRef.current, {
        center: [55.76, 37.64],
        zoom: 30,
        controls: [],
      });

      var searchControl = new window.ymaps.control.SearchControl({
        options: {
          provider: "yandex#search",
          resultsPerPage: 5,
        },
      });

      mapInstance.current.controls.add(searchControl);

      searchControl.search(card.title).then(function () {
        var geoObject = searchControl.getResultsArray()[0];
        if (geoObject) {
          var coordinates = geoObject.geometry.getCoordinates();

          var placemark = new window.ymaps.Placemark(coordinates, {
            hintContent: geoObject.getAddressLine(),
            iconLayout: "default#image",
            iconImageHref: "path_to_your_custom_icon.png",
            iconImageSize: [30, 42],
            iconImageOffset: [-15, -42],
          });

          mapInstance.current.geoObjects.add(placemark);
          mapInstance.current.setCenter(coordinates, 15);
        } else {
          alert("Организация не найдена");
        }
      });
    }
  };

  return (
    <div
      className={`popup ${isOpen ? "popup_active" : mounted && "popup_closed"}`}
      onClick={onOverlay}
    >
      <div className="popup__container">
        <div className="popup__header">
          <h2 className="popup__title">{card.title}</h2>
          <button
            className="button button_type_small button_type_gray"
            onClick={handleShare}
          >
            {copyStatus.share ? "Скопировано!" : "Поделиться"}
            <img
              className="button__image"
              src={shareIcon}
              alt="Иконка поделиться"
            />
          </button>
        </div>
        <div className="popup__columns">
          <div className="popup__column">
            {card.date && (
              <p className="popup__date">
                {card.date}
                {card.time && `, в ${card.time}`}
              </p>
            )}
            <h3 className="popup__subtitle">
              {card.section === "places" ? `Коротко о месте` : `Описание`}
            </h3>
            <p className="popup__text">{card.description}</p>
            <h3 className="popup__subtitle">Категория</h3>
            <p className="popup__text">{card.category}</p>
            {card.address && (
              <>
                <h3 className="popup__subtitle">Адрес</h3>
                <p
                  className="popup__text popup__text_type_copy"
                  onClick={() => handleCopy(card.address, "address")}
                >
                  {copyStatus.address ? "Скопировано!" : card.address}
                  <img
                    className="popup__text-image"
                    src={copyIcon}
                    alt="Иконка скопировать"
                  />
                </p>
              </>
            )}
            {card.country && (
              <>
                <h3 className="popup__subtitle">Страна</h3>
                <p className="popup__text popup__text_type_small">
                  {card.country}
                  <img
                    className="popup__flag"
                    src={card.flag}
                    alt={`Флаг ${card.country}`}
                  />
                </p>
              </>
            )}
            {card.tags && (
              <>
                <h3 className="popup__subtitle">Метки</h3>
                <div className="popup__tags">
                  {card.tags &&
                    card.tags.map((tag, index) => (
                      <p key={`${tag}-${index}`} className="popup__tag">
                        {tag}
                      </p>
                    ))}
                </div>
              </>
            )}
            {card.list && (
              <>
                <h3 className="popup__subtitle">Список</h3>
                <div className="popup__tags">
                  <p className="popup__tag">{card.list.name}</p>
                </div>
              </>
            )}
          </div>
          <div className="popup__column">
            {card.section !== "activities" && (
              <div className="popup__column-container">
                {card.price && size.width <= 450 && (
                  <div className="popup__price">
                    <a
                      href={card.link}
                      className="button button_type_small popup__price-button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Купить{" "}
                      <img
                        className="popup__price-icon"
                        src={basketIcon}
                        alt="Иконка корзины"
                      />
                    </a>
                    {card.price}₽
                  </div>
                )}
                <div className="popup__buttons">
                  <p
                    className={`popup__button ${
                      activeTab === "photo" ? "popup__button_active" : ""
                    }`}
                    onClick={() => setActiveTab("photo")}
                  >
                    Фото
                  </p>
                  <p
                    className={`popup__button ${
                      activeTab === "map" ? "popup__button_active" : ""
                    }`}
                    onClick={() => setActiveTab("map")}
                  >
                    {card.section === "things" ? "Где купить" : "На карте"}
                  </p>
                  <div className="popup__button-background"></div>
                </div>
              </div>
            )}
            <div className="popup__slider">
              <div
                className={`popup__slide ${
                  activeTab === "photo" ? "popup__slide_active" : ""
                }`}
              >
                <img className="popup__image" src={card.image} alt="Фото" />
              </div>
              <div
                className={`popup__slide ${
                  activeTab === "map" ? "popup__slide_active" : ""
                }`}
              >
                {card.section !== "things" ? (
                  <div id="map" className="popup__map" ref={mapRef}></div>
                ) : (
                  <div className="shops">
                    {shops.map((shop, index) => (
                      <div className="shop" key={index}>
                        <div className="shop__container">
                          <img
                            className="shop__image"
                            src={shop.image}
                            alt={`Иконка ${shop.title}`}
                          />
                          <h3 className="shop__title">{shop.title}</h3>
                        </div>
                        <a
                          href={`${shop.link}${card.title}`}
                          className="shop__link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Искать
                          <img
                            className="shop__link-image"
                            src={searchIcon}
                            alt="Иконка поиска"
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="popup__footer">
          {card.price && size.width > 450 && (
            <div className="popup__price">
              <a
                href={card.link}
                className="button button_type_small popup__price-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Купить{" "}
                <img
                  className="popup__price-icon"
                  src={basketIcon}
                  alt="Иконка корзины"
                />
              </a>
              {card.price}₽
            </div>
          )}
          <div className="popup__footer-container">
            <button className="button button_type_small">
              Добавить в список{" "}
              <img
                className="button__image"
                src={listIcon}
                alt="Иконка добавления списков"
              />
            </button>
            <button className="button button_type_small">
              Сохранить себе{" "}
              <img
                className="button__image"
                src={
                  card.section === "things"
                    ? thingsIcon
                    : card.section === "places"
                    ? placesIcon
                    : card.section === "activities"
                    ? activitiesIcon
                    : turismIcon
                }
                alt="Иконка сохранения"
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(126deg) brightness(107%) contrast(101%)",
                }}
              />
            </button>
          </div>
        </div>
        <button type="button" className="popup__close-button" onClick={onClose}>
          <img
            className="popup__close-button-image"
            src={closeIcon}
            alt="Кнопка крестик"
          />
        </button>
      </div>
    </div>
  );
}

export default CardPopup;
