import "./App.css";
import { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import ProtectedAuthRoute from "../ProtectedAuthRoute/ProtectedAuthRoute";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";

import AuthForm from "../AuthForm/AuthForm";

import * as MainApi from "../../utils/MainApi";
import Sidebar from "../Sidebar/Sidebar";
import Main from "../Main/Main";
import CardPopup from "../CardPopup/CardPopup";
import Profile from "../Profile/Profile";

import useWindowSize from "../../hooks/useWindowSize";

import {
  initialCards,
  initialCategories,
  initialUser,
  initialUserFriends,
  initialUserSubs,
  initialUserLists,
  initialUserWishs,
} from "../../utils/constants";
import Business from "../Business/Business";
import Settings from "../Settings/Settings";
import About from "../About/About";
import PopupAddCard from "../PopupAddCard/PopupAddCard";

function App() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState(null);

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const size = useWindowSize();

  const [currentUser, setCurrentUser] = useState(initialUser);
  const [currentUserFriends, setСurrentUserFriends] =
    useState(initialUserFriends);
  const [currentUserSubs, setСurrentUserSubs] = useState(initialUserSubs);
  const [currentUserLists, setСurrentUserLists] = useState(initialUserLists);
  const [currentUserWishs, setСurrentUserWishs] = useState(initialUserWishs);

  const [isLoggedIn, setLoggedIn] = useState();

  const [isInputsDisabled, setInputsDisabled] = useState(false);

  const [isCardPopupOpen, setCardPopupOpen] = useState(false);
  const [isPopupAddCardOpen, setPopupAddCardOpen] = useState(false);

  const [cards, setCards] = useState(initialCards);
  const [categories, setCategories] = useState(initialCategories);
  const [selectedCard, setSelectedCard] = useState({});

  const noSidebarPaths = ["/about", "/business", "/signup", "/signin"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cardsResponse, categoriesResponse] = await Promise.all([
          fetch("https://jelaem.ru/api/recommendations"),
          fetch("https://jelaem.ru/api/categories"),
        ]);

        const cardsData = await cardsResponse.json();
        const categoriesData = await categoriesResponse.json();

        setCards(cardsData);
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      const jwt = localStorage.getItem("jwt");
      MainApi.checkToken(jwt)
        .then(() => {
          setLoggedIn(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoggedIn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      Promise.all([
        MainApi.getInitialInfo(),
        MainApi.getUserFriends(),
        MainApi.getUserSubs(),
        MainApi.getUserLists(),
        MainApi.getUserWishs(),
      ])
        .then(([userData, userFriends, userSubs, userLists, userWishs]) => {
          setCurrentUser(userData);
          setСurrentUserFriends(userFriends);
          setСurrentUserSubs(userSubs);
          setСurrentUserLists(userLists);
          setСurrentUserWishs(userWishs);
        })

        .catch((error) => {
          console.log(error);
        });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const cardId = searchParams.get("w");
    if (cardId) {
      const card = cards.find((c) => `card${c.id}` === cardId);
      if (card) {
        setSelectedCard(card);
        setCardPopupOpen(true);
      }
    }
  }, [search, cards]);

  useEffect(() => {
    if (isCardPopupOpen || isSidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Очистка стиля при демонтировании компонента
    return () => {
      document.body.style.overflow = "";
    };
  }, [isCardPopupOpen, isSidebarOpen]);

  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка окна браузера в верхнюю часть страницы
  }, [pathname]); // Эффект срабатывает при каждом изменении пути

  function handleSetApiError(status) {
    if (status === 409) {
      setApiError("Пoльзователь с таким email уже зарегистрирован");
    }
    if (status === 401) {
      setApiError("Введена неправильная почта или пароль");
    }
    if (status === 500) {
      setApiError("На сервере произошла ошибка");
    }
  }

  function handleRegister(inputs) {
    setInputsDisabled(true);
    MainApi.register(inputs.name, inputs.email, inputs.password)
      .then(() => {
        handleAuthorize(inputs);
      })
      .catch((error) => {
        handleSetApiError(error);
      })
      .finally(() => {
        setInputsDisabled(false);
      });
  }

  function handleAuthorize(inputs) {
    setInputsDisabled(true);
    MainApi.authorize(inputs.email, inputs.password)
      .then((res) => {
        if (res.token) {
          localStorage.setItem("jwt", res.token);
          setLoggedIn(true);
          navigate("/me", { replace: true });
          setApiError(null);
        }
      })
      .catch((error) => {
        handleSetApiError(error);
      })
      .finally(() => {
        setInputsDisabled(false);
      });
  }

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setCardPopupOpen(true);
    navigate(`?w=card${card.id}`, { replace: true });
  };

  const handleAddClick = () => {
    setPopupAddCardOpen(true);
  };

  const closeAllPopups = () => {
    setCardPopupOpen(false);
    setPopupAddCardOpen(false);
    navigate(pathname, { replace: true });
  };

  function handleOverlayClick(e) {
    if (e) {
      if (e.target === e.currentTarget) {
        closeAllPopups();
      }
    }
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className={`root`}>
        <div
          className={`page ${
            pathname === "/signin" || pathname === "/signup"
              ? "page_type_max"
              : ""
          }`}
        >
          {(!noSidebarPaths.includes(pathname) || size.width <= 450) && (
            <Sidebar
              loggedIn={isLoggedIn}
              currentUser={currentUser}
              isOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
          )}
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/recommendations" replace />}
            />
            <Route
              path="/recommendations"
              element={
                <Main
                  onClickCard={handleCardClick}
                  cards={cards}
                  categories={categories}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              path="/:id/*"
              element={
                <Profile
                  currentUser={currentUser}
                  currentUserFriends={currentUserFriends}
                  currentUserSubs={currentUserSubs}
                  currentUserLists={currentUserLists}
                  currentUserWishs={currentUserWishs}
                  onClickCard={handleCardClick}
                  categories={categories}
                  setCardPopupOpen={setCardPopupOpen}
                  setSelectedCard={setSelectedCard}
                  handleAddClick={handleAddClick}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              path="/business"
              element={<Business toggleSidebar={toggleSidebar} />}
            />
            <Route
              path="/about"
              element={<About toggleSidebar={toggleSidebar} />}
            />
            <Route
              path="/settings"
              element={
                <Settings
                  currentUser={currentUser}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <ProtectedAuthRoute
                  element={AuthForm}
                  title={"Регистрация"}
                  subtitle={
                    "Добро пожаловать! Пожалуйста, введите ваши данные."
                  }
                  buttonText={"Регистрация"}
                  text={"Уже есть аккаунт?"}
                  textPath={"/signin"}
                  submitPath={"/me"}
                  link={"Войти"}
                  onSubmit={handleRegister}
                  apiError={apiError}
                  isInputsDisabled={isInputsDisabled}
                />
              }
            />
            <Route
              path="/signin"
              element={
                <ProtectedAuthRoute
                  element={AuthForm}
                  title={"Войти"}
                  subtitle={"С возвращением! Пожалуйста, введите ваши данные."}
                  buttonText={"Войти"}
                  text={"Новый пользователь?"}
                  textPath={"/signup"}
                  submitPath={"/me"}
                  link={"Зарегистрироваться"}
                  onSubmit={handleAuthorize}
                  apiError={apiError}
                  isInputsDisabled={isInputsDisabled}
                />
              }
            />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
          <CardPopup
            onOverlay={handleOverlayClick}
            isOpen={isCardPopupOpen}
            card={selectedCard}
            onClose={closeAllPopups}
          />
          <PopupAddCard
            onOverlay={handleOverlayClick}
            isOpen={isPopupAddCardOpen}
            onClose={closeAllPopups}
            categories={initialCategories}
          />
        </div>
      </div>
    </CurrentUserContext.Provider>
  );
}

export default App;
