import "./Business.css";
import React from "react";
import { Link } from "react-router-dom";

import jelaemBanner from "../../images/Banners/for-business.svg";

import card from "../../images/business/card.svg";
import cardBrand from "../../images/business/card-brands.svg";
import banner from "../../images/business/banner.svg";

import advantageIcon from "../../images/business/advantages.svg";
import mockup from "../../images/business/mac.svg";

import useWindowSize from "../../hooks/useWindowSize";

import sidebarIcon from "../../images/Sidebar/Close-button.svg";

import jelaemSmallBanner from "../../images/Banners/banner-small.png";

function Business({ toggleSidebar }) {
  const size = useWindowSize();
  return (
    <section className="business">
      <div className="business__header">
        <h2 className="business__title">Для бизнеса</h2>
        {size.width <= 450 ? (
          <button className="sidebar-button" onClick={toggleSidebar}>
            <img
              src={sidebarIcon}
              className="sidebar-button__image"
              alt="Иконка сайдбара"
            />
          </button>
        ) : (
          <Link to="/recommendations" className="business__button">
            На главную
          </Link>
        )}
      </div>

      <div className="statistic">
        <div className="statistic__container">
          <article className="statistic__card">
            <h4 className="statistic__title">Пользователей в сервисе</h4>
            <p className="statistic__subtitle">510 000</p>
          </article>
          <article className="statistic__card">
            <h4 className="statistic__title">Желаний загадано</h4>
            <p className="statistic__subtitle">3 467 253</p>
          </article>
          <article className="statistic__card">
            <h4 className="statistic__title">Месячная аудитория</h4>
            <p className="statistic__subtitle">292 000</p>
          </article>
          <article className="statistic__card">
            <h4 className="statistic__title">Желаний загадывается</h4>
            <div className="statistic__card-container">
              <p className="statistic__subtitle">58 000</p>
              <p className="statistic__day">в день</p>
            </div>
          </article>
        </div>
        <img
          className="statistic__image"
          src={size.width <= 450 ? jelaemSmallBanner : jelaemBanner}
          alt={`Банер 'Желаем для бизнеса'`}
        />
        {size.width > 450 && (
          <div className="statistic__container">
            <article className="statistic__card">
              <h4 className="statistic__title">Брендов в сервисе</h4>
              <p className="statistic__subtitle">112</p>
            </article>
            <article className="statistic__card">
              <h4 className="statistic__title">Карточек рекомендаций</h4>
              <p className="statistic__subtitle">1300</p>
            </article>
            <article className="statistic__card">
              <h4 className="statistic__title">Подписок на бренды</h4>
              <p className="statistic__subtitle">92 000</p>
            </article>
            <article className="statistic__card">
              <h4 className="statistic__title">Просмотров рекомендаций</h4>
              <div className="statistic__card-container">
                <p className="statistic__subtitle">203 000</p>
                <p className="statistic__day">в день</p>
              </div>
            </article>
          </div>
        )}
      </div>
      <h2 className="business__title">Желаем для бизнеса</h2>
      <div className="b-cards">
        <div className="b-cards__container">
          <article className="b-card">
            <h4 className="b-card__title">Карточка рекомендаций</h4>
            <img className="b-card__image" src={card} alt="Иконка карточки" />
            <div className="b-card__container">
              <p className="b-card__price">1990₽</p>
              <p className="b-card__month">в месяц</p>
            </div>
          </article>
          <article className="b-card">
            <h4 className="b-card__title">Карточка бренда (маленькая)</h4>
            <img
              className="b-card__image"
              src={cardBrand}
              alt="Иконка карточки"
            />
            <div className="b-card__container">
              <p className="b-card__price">9990₽</p>
              <p className="b-card__month">в месяц</p>
            </div>
          </article>
          <article className="b-card">
            <h4 className="b-card__title">Карточка бренда (большая)</h4>
            <img
              className="b-card__image"
              src={cardBrand}
              alt="Иконка карточки"
            />
            <div className="b-card__container">
              <p className="b-card__price">14990₽</p>
              <p className="b-card__month">в месяц</p>
            </div>
          </article>
          <article className="b-card">
            <h4 className="b-card__title">Баннер</h4>
            <img className="b-card__image" src={banner} alt="Иконка карточки" />
            <div className="b-card__container">
              <p className="b-card__price">49990₽</p>
              <p className="b-card__month">в месяц</p>
            </div>
          </article>
        </div>
      </div>
      <h2 className="business__title">Преимущества</h2>
      <div className="advantages">
        <div className="advantages__column">
          <article className="advantages__item">
            <img
              className="advantages__image"
              src={advantageIcon}
              alt="Значок преимущества"
            />
            <p className="advantages__title">Галочка в названии бренда</p>
          </article>
          <article className="advantages__item">
            <img
              className="advantages__image"
              src={advantageIcon}
              alt="Значок преимущества"
            />
            <p className="advantages__title">Создание карточек рекомандаций</p>
          </article>
          <article className="advantages__item">
            <img
              className="advantages__image"
              src={advantageIcon}
              alt="Значок преимущества"
            />
            <p className="advantages__title">
              Бизнес-аккаунт бесплатно и навсегда
            </p>
          </article>
          <article className="advantages__item">
            <img
              className="advantages__image"
              src={advantageIcon}
              alt="Значок преимущества"
            />
            <p className="advantages__title">
              Трафик пользователей на ваш сайт
            </p>
          </article>
          <article className="advantages__item">
            <img
              className="advantages__image"
              src={advantageIcon}
              alt="Значок преимущества"
            />
            <p className="advantages__title">Быстрый доступ к вашим товарам</p>
          </article>
        </div>
        <img
          className="advantages__mockup"
          src={mockup}
          alt="Мокап желаем для бизнеса"
        />
      </div>
    </section>
  );
}

export default Business;
