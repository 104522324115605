import "./UserPanel.css";
import React from "react";

import markIcon from "../../images/profile/mark.svg";
import cakeIcon from "../../images/profile/cake.svg";

function UserPanel({ user }) {
  const formatBirthDate = (dateString) => {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];
    const [day, month, year] = dateString.split(".");
    return `${parseInt(day)} ${months[parseInt(month) - 1]}`;
  };

  return (
    <div className="panel">
      <img
        className="panel__image"
        src={user.userInfo.image}
        alt={`Фотография пользователя`}
      />
      <div className="panel__container">
        <div className="panel__info">
          <h3 className="panel__title">
            {user.userInfo.name} {user.userInfo.surname}
          </h3>
          <div className="panel__row">
            <p className="panel__subtitle">
              <img
                className="panel__subtitle-image"
                src={markIcon}
                alt="Иконка метки"
              />
              {user.general.city}
            </p>
            <p className="panel__subtitle">
              <img
                className="panel__subtitle-image"
                src={cakeIcon}
                alt="Иконка торта"
              />
              {formatBirthDate(user.userInfo.birth)}
            </p>
          </div>
        </div>
        <button className="button">Редактировать профиль</button>
      </div>
    </div>
  );
}

export default UserPanel;
