import "./Settings.css";
import React, { useState, useEffect, useRef } from "react";
import useFormsValidation from "../../hooks/useFormsValidation";
import InputWithError from "../InputWithError/InputWithError";
import DropdownInput from "../DropdownInput/DropdownInput";
import useDropdown from "../../hooks/useDropdown";
import usePhoneMask from "../../hooks/usePhoneMask";
import useBirthMask from "../../hooks/useBirthMask";
import pictureIcon from "../../images/input/picture.svg";
import ErrorImage from "../../images/input/input-error.svg";

import sidebarIcon from "../../images/Sidebar/Close-button.svg";

const Settings = ({ currentUser, isInputsDisabled, toggleSidebar }) => {
  const { handleChangeInput, inputs, isError, messageError, setInputs } =
    useFormsValidation({});
  const { onPhonePaste, onPhoneInput, onPhoneKeyDown } =
    usePhoneMask(handleChangeInput);
  const { onBirthInput, onBirthKeyDown } = useBirthMask(handleChangeInput);
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);
  const [isVisible, setVisible] = useState({
    oldPassword: false,
    newPassword: false,
    repeatPassword: false,
  });

  const languages = ["Русский", "English", "中文"];
  const themes = ["Тёмная", "Светлая", "Системная"];
  const policy = ["Все пользователи", "Только мои друзья", "Никто"];

  const {
    dropdownState: infoPolicyState,
    handleToggle: toggleInfoPolicy,
    handleSelect: selectInfoPolicy,
    dropdownRef: infoPolicyRef,
  } = useDropdown();
  const {
    dropdownState: wishsPolicyState,
    handleToggle: toggleWishsPolicy,
    handleSelect: selectWishsPolicy,
    dropdownRef: wishsPolicyRef,
  } = useDropdown();
  const {
    dropdownState: languageState,
    handleToggle: toggleLanguage,
    handleSelect: selectLanguage,
    dropdownRef: languageRef,
  } = useDropdown();
  const {
    dropdownState: themeState,
    handleToggle: toggleTheme,
    handleSelect: selectTheme,
    dropdownRef: themeRef,
  } = useDropdown();

  useEffect(() => {
    if (currentUser) {
      const newInputs = {};
      if (currentUser.userInfo) {
        Object.keys(currentUser.userInfo).forEach((key) => {
          newInputs[key] = currentUser.userInfo[key];
        });
      }
      if (currentUser.privacy) {
        Object.keys(currentUser.privacy).forEach((key) => {
          newInputs[key] = currentUser.privacy[key];
        });
      }
      if (currentUser.general) {
        Object.keys(currentUser.general).forEach((key) => {
          newInputs[key] = currentUser.general[key];
        });
      }
      setInputs(newInputs);
    }
  }, [currentUser, setInputs]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setInputs((prev) => ({ ...prev, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setInputs((prev) => ({ ...prev, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleOverlayClick = () => {
    fileInputRef.current.click();
  };

  const handleClickEye = (field) => {
    setVisible((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };

  const handleMoseDownInputImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <section className="settings">
      <div className="caption">
        <h2 className="caption__title">Настройки</h2>
        <button className="sidebar-button" onClick={toggleSidebar}>
          <img
            src={sidebarIcon}
            className="sidebar-button__image"
            alt="Иконка сайдбара"
          />
        </button>
      </div>
      <form className="settings__form">
        <h3 className="settings__title">Профиль</h3>
        <div className="settings__container">
          <div className="settings__inputs settings__inputs_type_columns">
            <InputWithError
              name="name"
              value={inputs.name}
              onChange={handleChangeInput}
              placeholder="Имя"
              pattern="^[\-\sa-zA-Zа-яА-Я]*$"
              isError={isError.name}
              messageError={messageError.name}
              isInputsDisabled={isInputsDisabled}
            />
            <InputWithError
              name="surname"
              value={inputs.surname}
              onChange={handleChangeInput}
              placeholder="Фамилия"
              pattern="^[\-\sa-zA-Zа-яА-Я]*$"
              isError={isError.surname}
              messageError={messageError.surname}
              isInputsDisabled={isInputsDisabled}
            />
            <InputWithError
              name="mail"
              value={inputs.mail}
              onChange={handleChangeInput}
              placeholder="Почта"
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              isError={isError.mail}
              messageError={messageError.mail}
              isInputsDisabled={isInputsDisabled}
            />
            <InputWithError
              name="phone"
              value={inputs.phone}
              onChange={onPhoneInput}
              onPaste={onPhonePaste}
              onKeyDown={onPhoneKeyDown}
              placeholder="Телефон"
              pattern="^(\+7|8)\s?\(\d{3}\)\s?\d{3}-\d{2}-\d{2}$"
              isError={isError.phone}
              messageError={messageError.phone}
              isInputsDisabled={isInputsDisabled}
            />
            <InputWithError
              name="birth"
              value={inputs.birth}
              onChange={onBirthInput}
              onKeyDown={onBirthKeyDown}
              placeholder="Дата рождения"
              pattern="^\d{2}\.\d{2}\.\d{4}$"
              isError={isError.birth}
              messageError={messageError.birth}
              isInputsDisabled={isInputsDisabled}
            />
            <InputWithError
              name="about"
              value={inputs.about}
              onChange={handleChangeInput}
              placeholder="О себе"
              pattern="^[\-\sa-zA-Zа-яА-Я]*$"
              isError={isError.about}
              messageError={messageError.about}
              isInputsDisabled={isInputsDisabled}
            />
          </div>
          <div className="inputs-container input-container_type_settings">
            <div
              className={`input-container input-container__image ${
                isDragOver ? "input-container__drag-over" : ""
              }`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={handleOverlayClick}
            >
              <img
                className="input-image"
                src={inputs.image}
                alt="Фотография пользователя"
              />
              <div
                className={`input__overlay ${
                  isDragOver ? "input__overlay_active" : ""
                }`}
              >
                <img
                  className="input__overlay-image"
                  src={pictureIcon}
                  alt="Иконка картинки"
                />
                <p className="input__overlay-title">
                  Перетащите изображение или нажмите для загрузки
                </p>
                JPG, PNG, НЕ БОЛЕЕ 3 МБ
              </div>
              <input
                className="input input-file"
                name="image"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                disabled={isInputsDisabled}
                ref={fileInputRef}
              />
            </div>
          </div>
        </div>
        <div className="settings__footer">
          <button className="settings__button">Сохранить изменения</button>
          <button className="settings__button settings__button_type_logout">
            Выйти из аккаунта
          </button>
        </div>
      </form>
      <form className="settings__form">
        <h3 className="settings__title">Приватность</h3>
        <div className="settings__inputs">
          <DropdownInput
            name="infoPolicy"
            value={inputs.infoPolicy}
            options={policy}
            onToggle={toggleInfoPolicy}
            onSelect={(option) =>
              setInputs((prev) => ({
                ...prev,
                infoPolicy: selectInfoPolicy(option),
              }))
            }
            dropdownState={infoPolicyState}
            isInputsDisabled={isInputsDisabled}
            placeholder="Кто может видить личную информацию"
            dropdownRef={infoPolicyRef}
          />
          <DropdownInput
            name="wishsPolicy"
            value={inputs.wishsPolicy}
            options={policy}
            onToggle={toggleWishsPolicy}
            onSelect={(option) =>
              setInputs((prev) => ({
                ...prev,
                wishsPolicy: selectWishsPolicy(option),
              }))
            }
            dropdownState={wishsPolicyState}
            isInputsDisabled={isInputsDisabled}
            placeholder="Кто может видить мои желания"
            dropdownRef={wishsPolicyRef}
          />
        </div>
        <div className="settings__footer">
          <button className="settings__button">Сохранить изменения</button>
        </div>
      </form>
      <form className="settings__form">
        <h3 className="settings__title">Пароль</h3>
        <div className="settings__inputs">
          <InputWithError
            name="oldPassword"
            value={inputs.oldPassword}
            onChange={handleChangeInput}
            placeholder="Старый пароль"
            pattern="^(?=.*[\p{Lu}])(?=.*\d).{3,}$"
            isError={isError.oldPassword}
            messageError={messageError.oldPassword}
            isInputsDisabled={isInputsDisabled}
            type={isVisible.oldPassword ? "text" : "password"}
            onEyeClick={() => handleClickEye("oldPassword")}
            isEyeVisible={isVisible.oldPassword}
          />
          <InputWithError
            name="newPassword"
            value={inputs.newPassword}
            onChange={handleChangeInput}
            placeholder="Новый пароль"
            pattern="^(?=.*[\p{Lu}])(?=.*\d).{3,}$"
            isError={isError.newPassword}
            messageError={messageError.newPassword}
            isInputsDisabled={isInputsDisabled}
            type={isVisible.newPassword ? "text" : "password"}
            onEyeClick={() => handleClickEye("newPassword")}
            isEyeVisible={isVisible.newPassword}
          />
          <InputWithError
            name="repeatPassword"
            value={inputs.repeatPassword}
            onChange={handleChangeInput}
            placeholder="Повторите пароль"
            pattern="^(?=.*[\p{Lu}])(?=.*\d).{3,}$"
            isError={isError.repeatPassword}
            messageError={messageError.repeatPassword}
            isInputsDisabled={isInputsDisabled}
            type={isVisible.repeatPassword ? "text" : "password"}
            onEyeClick={() => handleClickEye("repeatPassword")}
            isEyeVisible={isVisible.repeatPassword}
          />
        </div>
        <div className="settings__footer">
          <button className="settings__button">Сохранить изменения</button>
        </div>
      </form>
      <form className="settings__form">
        <h3 className="settings__title">Общее</h3>
        <div className="settings__inputs">
          <DropdownInput
            name="language"
            value={inputs.language}
            options={languages}
            onToggle={toggleLanguage}
            onSelect={(option) =>
              setInputs((prev) => ({
                ...prev,
                language: selectLanguage(option),
              }))
            }
            dropdownState={languageState}
            isInputsDisabled={isInputsDisabled}
            placeholder="Язык"
            dropdownRef={languageRef}
          />
          <InputWithError
            name="city"
            value={inputs.city}
            onChange={handleChangeInput}
            placeholder="Город"
            pattern="^[\-\sa-zA-Zа-яА-Я]*$"
            isError={isError.city}
            messageError={messageError.city}
            isInputsDisabled={isInputsDisabled}
          />
          <DropdownInput
            name="theme"
            value={inputs.theme}
            options={themes}
            onToggle={toggleTheme}
            onSelect={(option) =>
              setInputs((prev) => ({ ...prev, theme: selectTheme(option) }))
            }
            dropdownState={themeState}
            isInputsDisabled={isInputsDisabled}
            placeholder="Тема"
            dropdownRef={themeRef}
          />
          <div className="inputs-container">
            <p
              className={`input__title ${
                inputs.path ? "input__title_active" : ""
              } ${isError.path ? "input__title_type_error" : ""}`}
            >
              Адрес профиля
            </p>
            <div
              className={`input-container ${
                isError.path ? `input-container_type_error` : ""
              }`}
            >
              <p className="input-path" onMouseDown={handleMoseDownInputImage}>
                https://jelaem.ru/
              </p>
              <input
                className={`input input_type_path ${
                  isError.path ? `input_type_error` : ""
                }`}
                name="path"
                type="text"
                value={inputs.path || ""}
                onChange={handleChangeInput}
                placeholder="адрес профиля"
                pattern="[A-Za-z0-9]*"
                disabled={isInputsDisabled}
                required
              />
            </div>
            <span
              className={`input-error ${
                isError.path ? "input-error_active" : ""
              }`}
            >
              <img
                className="input-error__image"
                src={ErrorImage}
                alt="Восклицательный знак"
              />
              {isError.path &&
                (messageError.path === `Введите данные в указанном формате.`
                  ? `Неверный формат.`
                  : messageError.path)}
            </span>
          </div>
        </div>
        <div className="settings__footer">
          <button className="settings__button">Сохранить изменения</button>
        </div>
      </form>
    </section>
  );
};

export default Settings;
