import React, { useState } from "react";
import "./Calendar.css";

import arrowleft from "../../images/calendar/arrow-left.svg";
import arrowRight from "../../images/calendar/arrow-right.svg";

import useWindowSize from "../../hooks/useWindowSize";

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);

  const { width } = useWindowSize();

  const getStartDay = (date) => {
    const day = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return day === 0 ? 6 : day - 1;
  };

  const prevWeek = () => {
    if (width < 451) {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() - 7);
      setCurrentDate(newDate);
    } else {
      setCurrentDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
      );
    }
  };

  const nextWeek = () => {
    if (width < 451) {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + 7);
      setCurrentDate(newDate);
    } else {
      setCurrentDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      );
    }
  };

  const renderDays = () => {
    const days = [];
    const today = new Date();
    const isSmallScreen = width < 451;

    // Определяем начало и конец недели
    const dayOffset = currentDate.getDay() || 7; // Считаем воскресенье последним днем
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - dayOffset + 1);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    if (isSmallScreen) {
      // Листание недель
      for (let i = 0; i < 7; i++) {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        const isCurrentMonth = date.getMonth() === currentDate.getMonth();
        days.push(
          <div
            key={date.toISOString()}
            className={`calendar__day ${
              isToday(date) ? "calendar__day_type_now" : ""
            } ${selectedDay === date.getDate() ? "calendar__day_active" : ""} ${
              !isCurrentMonth ? "calendar__day_disable" : ""
            }`}
            onClick={() => handleDayClick(date)}
          >
            {date.getDate()}
          </div>
        );
      }
    } else {
      // Отображение всех дней месяца
      const startDay = getStartDay(currentDate);
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
      const daysInPrevMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getDate();

      // Дни предыдущего месяца
      for (let i = startDay - 1; i >= 0; i--) {
        const prevDay = daysInPrevMonth - i;
        days.push(
          <div
            key={`prev-${prevDay}`}
            className="calendar__day calendar__day_disable"
          >
            {prevDay}
          </div>
        );
      }

      // Дни текущего месяца
      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          i
        );
        days.push(
          <div
            key={date.toISOString()}
            className={`calendar__day ${
              isToday(date) ? "calendar__day_type_now" : ""
            } ${selectedDay === date.getDate() ? "calendar__day_active" : ""}`}
            onClick={() => handleDayClick(date)}
          >
            {i}
          </div>
        );
      }

      // Дни следующего месяца
      const nextDays = 42 - (startDay + daysInMonth);
      for (let i = 1; i <= nextDays; i++) {
        days.push(
          <div
            key={`next-${i}`}
            className="calendar__day calendar__day_disable"
          >
            {i}
          </div>
        );
      }
    }

    return days;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const handleDayClick = (date) => {
    setSelectedDay(date.getDate());
  };

  const monthNames = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  return (
    <div className="calendar">
      <div className="calendar__header">
        <h4 className="calendar__title">События</h4>
        <div className="calendar__container">
          <button className="calendar__button" onClick={prevWeek}>
            <img
              className="calendar__button-image"
              src={arrowleft}
              alt="Стрелка влево"
            />
          </button>
          <p className="calendar__month">{`${
            monthNames[currentDate.getMonth()]
          } ${currentDate.getFullYear()}`}</p>
          <button className="calendar__button" onClick={nextWeek}>
            <img
              className="calendar__button-image"
              src={arrowRight}
              alt="Стрелка вправо"
            />
          </button>
        </div>
      </div>
      <div className="calendar__weekdays">
        <div className="calendar__weekday">Пн</div>
        <div className="calendar__weekday">Вт</div>
        <div className="calendar__weekday">Ср</div>
        <div className="calendar__weekday">Чт</div>
        <div className="calendar__weekday">Пт</div>
        <div className="calendar__weekday">Сб</div>
        <div className="calendar__weekday">Вс</div>
      </div>
      <div className="calendar__days">{renderDays()}</div>
    </div>
  );
};

export default Calendar;
