import "./About.css";
import React from "react";
import { Link } from "react-router-dom";

import pointLastIcon from "../../images/business/point-last.svg";

import useWindowSize from "../../hooks/useWindowSize";

import sidebarIcon from "../../images/Sidebar/Close-button.svg";

const About = ({ toggleSidebar }) => {
  const size = useWindowSize();

  return (
    <section className="about">
      <div className="business__header">
        <h2 className="business__title">О сервисе</h2>
        {size.width <= 450 ? (
          <button className="sidebar-button" onClick={toggleSidebar}>
            <img
              src={sidebarIcon}
              className="sidebar-button__image"
              alt="Иконка сайдбара"
            />
          </button>
        ) : (
          <Link to="/recommendations" className="business__button">
            На главную
          </Link>
        )}
      </div>
      <article className="about__card">
        <p className="about__text about__text_type_big">
          <Link to="/recommendations" className="about__text_type_blue">
            «Желаем»
          </Link>{" "}
          — это удобный онлайн-сервис для обмена и воплощения пользовательских
          желаний, предназначенный для создания и управления списками желаний
          для любого случая!
        </p>
      </article>
      <div className="about__container">
        <div className="about__column">
          <article className="about__card">
            <h4 className="about__title">
              Что такое вишлист и для чего он нужен?
            </h4>
            <p className="about__text">
              Список желаний — это ваш персональный каталог того, что вы хотите
              получить в подарок, места, которые хотели бы посетить, или
              мероприятия, на которые хотели бы пойти. Это может быть что
              угодно: от товаров до путешествий и услуг. Создать список можно на
              любой случай — будь то день рождения, свадьба, юбилей,
              профессиональный праздник или просто как повод порадовать себя и
              близких. Список желаний помогает точно определить ваши желания,
              избавляет от ненужных или неуместных подарков и позволяет
              предлагать друзьям встретиться в желаемых местах или сходить
              вместе на мероприятия.
            </p>
          </article>
          <article className="about__card">
            <h4 className="about__title">
              Как еще можно использовать вишлист?
            </h4>
            <p className="about__text">
              Списки желаний могут служить также вашим личным каталогом желаний.
              Все, что вы хотели купить, посетить или испытать «когда-нибудь
              потом», теперь будет организовано и всегда под рукой. Планируете
              мероприятие, поход или путешествие с друзьями? Создайте совместный
              список желаний и распределите задачи между участниками. С «Желаем»
              все желания становятся легко доступными и организованными!
            </p>
          </article>
        </div>
        <article className="about__card">
          <h4 className="about__title">Как создать вишлист?</h4>
          <div>
            <div className="about__points">
              <div className="about__point">
                <div className="about__circle">01</div>
                <div className="about__line about__line_type_small"></div>
              </div>
              <p className="about__point-title">
                Зарегистрируйтесь на платформе через email или социальные сети.
              </p>
            </div>
            <div className="about__points">
              <div className="about__point">
                <div className="about__circle">02</div>
                <div className="about__line"></div>
              </div>
              <p className="about__point-title">
                Составьте свой список желаний, добавляя товары, услуги, места
                для посещения или мероприятия, указывая все детали, включая
                информацию и ссылки для покупки.
              </p>
            </div>
            <div className="about__points">
              <div className="about__point">
                <div className="about__circle">03</div>
                <div className="about__line"></div>
              </div>
              <p className="about__point-title">
                Откройте доступ к своему списку желаний для друзей и близких,
                поделившись ссылкой.
              </p>
            </div>
            <div className="about__points">
              <div className="about__point">
                <div className="about__circle">04</div>
                <div className="about__line"></div>
              </div>
              <p className="about__point-title">
                Добавьте друзей через сервис, чтобы они могли видеть ваши
                желания и резервировать подарки или предлагать встречи в
                желаемых местах.
              </p>
            </div>
            <div className="about__points">
              <div className="about__point">
                <img src={pointLastIcon} alt="Иконка готовности" />
              </div>
              <p className="about__point-title">
                Всё готово! Теперь можно расслабиться и получать желаемое.
              </p>
            </div>
          </div>
        </article>
      </div>
      <article className="about__card">
        <p className="about__text">
          Друзьям и близким больше не придется ломать голову и носиться по
          городу в поисках подарка. А вы получите только то, что пожелали.
          Никаких «сувениров», сомнительных скульптур для интерьера и других
          вещей, созданных только для того, чтобы заполнить вакуум в ответ на
          вопрос: «Что подарить?!».
        </p>
        <p className="about__text">
          Мы делаем процесс дарения и получения подарков простым и приятным для
          каждого участника этого сакрального процесса.
        </p>
      </article>
      <article className="about__card">
        <h4 className="about__title">Как создать вишлист?</h4>
        <p className="about__text">
          Служба поддержки пользователей:{" "}
          <a href="mailto:help@jelaem.ru" className="about__text_type_blue">
            help@jelaem.ru
          </a>
        </p>
        <p className="about__text">
          Сотрудничество:{" "}
          <a href="mailto:info@jelaem.ru" className="about__text_type_blue">
            info@jelaem.ru
          </a>
        </p>
      </article>
    </section>
  );
};

export default About;
