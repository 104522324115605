import "./PopupAddCard.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useFormsValidation from "../../hooks/useFormsValidation";
import useDropdown from "../../hooks/useDropdown";

import InputWithError from "../InputWithError/InputWithError";
import DropdownInput from "../DropdownInput/DropdownInput";

import closeIcon from "../../images/Popup/close-icon.svg";

const PopupAddCard = ({
  onOverlay,
  isOpen,
  card,
  onClose,
  isInputsDisabled,
  categories,
}) => {
  const [mounted, setMounted] = useState(false);
  const [type, setType] = useState("");
  const [lists, setLists] = useState([]);

  const { pathname } = useLocation();

  const { handleChangeInput, inputs, isError, messageError, setInputs } =
    useFormsValidation({});

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
      const routeEnd = pathname.split("/").pop();
      setType(routeEnd);
      setLists(categories[routeEnd] || []);
    }
  }, [isOpen, pathname, categories]);

  const {
    dropdownState: categoryDropdownState,
    handleToggle: toggleCategoryDropdown,
    handleSelect: selectCategory,
    dropdownRef: categoryDropdownRef,
  } = useDropdown();

  const handleCategorySelect = (category) => {
    setInputs((prev) => ({ ...prev, category }));
    selectCategory(category);
  };

  return (
    <div
      className={`popup ${isOpen ? "popup_active" : mounted && "popup_closed"}`}
      onClick={onOverlay}
    >
      <div className="popup__container popup__container_type_big">
        <div className="add">
          <h3 className="add__title">Новое желание</h3>
          <form className="add__form">
            <div className="add__column">
              <InputWithError
                name="title"
                value={inputs.title}
                onChange={handleChangeInput}
                placeholder="Название"
                pattern="[\s\S]*"
                isError={isError.title}
                messageError={messageError.title}
                isInputsDisabled={isInputsDisabled}
              />
              <InputWithError
                name="description"
                value={inputs.description}
                onChange={handleChangeInput}
                placeholder="Описание желания"
                pattern="[\s\S]*"
                isError={isError.description}
                messageError={messageError.description}
                isInputsDisabled={isInputsDisabled}
                isTextarea={true}
              />
              <DropdownInput
                name="category"
                value={inputs.category}
                options={lists}
                onToggle={toggleCategoryDropdown}
                onSelect={handleCategorySelect}
                dropdownState={categoryDropdownState}
                isInputsDisabled={isInputsDisabled}
                placeholder="Категория"
                dropdownRef={categoryDropdownRef}
              />
            </div>
          </form>
        </div>
        <button type="button" className="popup__close-button" onClick={onClose}>
          <img
            className="popup__close-button-image"
            src={closeIcon}
            alt="Кнопка крестик"
          />
        </button>
      </div>
    </div>
  );
};

export default PopupAddCard;
