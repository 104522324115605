import { useCallback } from "react";

const useBirthMask = (handleChangeInput) => {
  const getInputNumbersValue = (value) => {
    return value.replace(/\D/g, "");
  };

  const onBirthInput = useCallback(
    (e) => {
      const input = e.target;
      let inputNumbersValue = getInputNumbersValue(input.value);
      const selectionStart = input.selectionStart;
      let formattedInputValue = "";

      if (!inputNumbersValue) {
        return (input.value = "");
      }

      if (input.value.length !== selectionStart) {
        if (e.data && /\D/g.test(e.data)) {
          input.value = inputNumbersValue;
        }
        return;
      }

      if (inputNumbersValue.length > 0) {
        formattedInputValue = inputNumbersValue.substring(0, 2);
      }
      if (inputNumbersValue.length >= 3) {
        formattedInputValue += "." + inputNumbersValue.substring(2, 4);
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += "." + inputNumbersValue.substring(4, 8);
      }

      input.value = formattedInputValue;
      handleChangeInput(e);
    },
    [handleChangeInput]
  );

  const onBirthKeyDown = useCallback((e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    if (e.keyCode === 8 && inputValue.length === 1) {
      e.target.value = "";
    }
  }, []);

  return { onBirthInput, onBirthKeyDown };
};

export default useBirthMask;
