import React from "react";
import "./BrandCard.css";

function BrandCard({ brand }) {
  const cardStyle = {
    backgroundImage: `url(${brand.background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div
      className={`brand-card ${brand.isLarge ? "brand-card_type_large" : ""}`}
      style={cardStyle}
    >
      <img
        className="brand-card__image"
        src={brand.image}
        alt={`Аватарка ${brand.name}`}
      ></img>
      <p className="brand-card__title">{brand.name}</p>
    </div>
  );
}

export default BrandCard;
