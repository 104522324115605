import "./Logo.css";
import React from "react";
import { Link } from "react-router-dom";
import logoJelaem from "../../images/Logo/jelaem-logo.svg";
import logoJelaemBlue from "../../images/Logo/jelaem-logo-type-blue.svg";

function Logo({ type }) {
  return (
    <Link to="/" className="logo">
      <img
        className="logo__image"
        src={type === "blue" ? logoJelaemBlue : logoJelaem}
        alt="Логотип Желаем"
      />
    </Link>
  );
}

export default Logo;
