import "./Sidebar.css";
import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Logo from "../Logo/Logo";

import recomendIcon from "../../images/Sidebar/recomend.svg";
import businessIcon from "../../images/Sidebar/business.svg";
import aboutIcon from "../../images/Sidebar/about.svg";
import friendsIcon from "../../images/Sidebar/friens.svg";
import thingsIcon from "../../images/Sidebar/things.svg";
import placesIcon from "../../images/Sidebar/places.svg";
import calenderIcon from "../../images/Sidebar/calender.svg";
import travelIcon from "../../images/Sidebar/travel.svg";

import settingsIcon from "../../images/Sidebar/settings.svg";
import helpIcon from "../../images/Sidebar/help.svg";

import sidebarIcon from "../../images/Sidebar/Close-button.svg";

function Sidebar({ loggedIn, currentUser, isOpen, toggleSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const links = [
    {
      image: recomendIcon,
      title: "Рекомендации",
      textPath: "/recommendations",
      rec: true,
    },
    {
      image: businessIcon,
      title: "Желаем для Бизнеса",
      textPath: "/business",
    },
    {
      image: aboutIcon,
      title: "О сервисе",
      textPath: "/about",
    },
    {
      image: friendsIcon,
      title: "Друзья",
      textPath: "/friends",
    },
  ];

  const wishLinks = [
    {
      image: thingsIcon,
      title: "Желаю Вещи",
      textPath: "/things",
    },
    {
      image: placesIcon,
      title: "Желаю Места",
      textPath: "/places",
    },
    {
      image: calenderIcon,
      title: "Желаю Мероприятия",
      textPath: "/activities",
    },
    {
      image: travelIcon,
      title: "Желаю Путешествовать",
      textPath: "/travel",
    },
  ];

  const handleUserClick = () => {
    navigate(`${currentUser.id}`);
    toggleSidebar();
  };

  const checkActiveLink = (textPath) => {
    return pathname.endsWith(textPath);
  };

  return (
    <div className={`sidebar ${isOpen ? "" : "sidebar_hidden"}`}>
      <div>
        <div className="sidebar__header">
          <Logo type="blue" />
          <button
            className="sidebar-button sidebar-button_static"
            onClick={toggleSidebar}
          >
            <img
              src={sidebarIcon}
              className="sidebar-button__image"
              alt="Иконка сайдбара"
            />
          </button>
        </div>
        <nav className="sidebar__navigation">
          <div className="user" onClick={handleUserClick}>
            <div className="user__container">
              <img
                className="user__image"
                src={currentUser.userInfo.image}
                alt="Фото профиля"
              ></img>
              <div className="user__info">
                <h2 className="user__title">
                  {currentUser.userInfo.name} {currentUser.userInfo.surname}
                </h2>
                <p className="user__text">{currentUser.userInfo.about}</p>
              </div>
            </div>
          </div>
          <ul className="links">
            {links.map((link) => (
              <li className="link" key={link.textPath}>
                <Link
                  to={
                    link.title === "Друзья"
                      ? `${currentUser.id}${link.textPath}`
                      : link.textPath
                  }
                  className={`link__container ${
                    checkActiveLink(link.textPath)
                      ? "link__container_active"
                      : ""
                  }`}
                  onClick={toggleSidebar}
                >
                  <img
                    className={`link__image ${
                      checkActiveLink(link.textPath) ? "link__image_active" : ""
                    }`}
                    src={link.image}
                    alt={`Иконка ${link.title}`}
                  ></img>
                  <h3
                    className={`link__title ${
                      checkActiveLink(link.textPath) ? "link__title_active" : ""
                    }`}
                  >
                    {link.title}
                  </h3>
                </Link>
              </li>
            ))}
            <p className="sidebar__title">Мои желания</p>
            {wishLinks.map((link) => (
              <li className="link" key={link.textPath}>
                <Link
                  to={`${currentUser.id}${link.textPath}`}
                  className={`link__container ${
                    checkActiveLink(link.textPath)
                      ? "link__container_active"
                      : ""
                  }`}
                  onClick={toggleSidebar}
                >
                  <img
                    className={`link__image ${
                      checkActiveLink(link.textPath) ? "link__image_active" : ""
                    }`}
                    src={link.image}
                    alt={`Иконка ${link.title}`}
                  ></img>
                  <h3
                    className={`link__title ${
                      checkActiveLink(link.textPath) ? "link__title_active" : ""
                    }`}
                  >
                    {link.title}
                  </h3>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="sidebar__footer">
        <nav className="sidebar__navigation">
          <ul className="links">
            <p className="sidebar__title">Параметры</p>
            <li className="link" key="settings">
              <Link
                to="/settings"
                className={`link__container ${
                  checkActiveLink("/settings") ? "link__container_active" : ""
                }`}
                onClick={toggleSidebar}
              >
                <img
                  className={`link__image ${
                    checkActiveLink("/settings") ? "link__image_active" : ""
                  }`}
                  src={settingsIcon}
                  alt="Иконка Настройки"
                ></img>
                <h3
                  className={`link__title ${
                    checkActiveLink("/settings") ? "link__title_active" : ""
                  }`}
                >
                  Настройки
                </h3>
              </Link>
            </li>
            <li className="link" key="support">
              <Link
                to="/support"
                className={`link__container ${
                  checkActiveLink("/support") ? "link__container_active" : ""
                }`}
                onClick={toggleSidebar}
              >
                <img
                  className={`link__image ${
                    checkActiveLink("/support") ? "link__image_active" : ""
                  }`}
                  src={helpIcon}
                  alt="Иконка Поддержка"
                ></img>
                <h3
                  className={`link__title ${
                    checkActiveLink("/support") ? "link__title_active" : ""
                  }`}
                >
                  Поддержка
                </h3>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
