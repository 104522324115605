import "./UserNav.css";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import thingsIcon from "../../images/wishs/things.svg";
import placesIcon from "../../images/wishs/places.svg";
import activitiesIcon from "../../images/wishs/activities.svg";
import turismIcon from "../../images/wishs/travel.svg";
import Calendar from "../Calendar/Calendar";

function UserNav({ wishs, friends, subs }) {
  const location = useLocation();
  const navigate = useNavigate();

  const countItemsInSection = (section) => {
    return wishs.filter((card) => card.section === section).length;
  };

  const appendCurrentRoute = (path) => {
    return location.pathname + path;
  };

  const navigateTo = (path) => {
    navigate(appendCurrentRoute(path));
  };

  return (
    <section className="user-nav">
      <div className="user-nav__container">
        <div className="nav nav_type_cursor">
          <h4 className="nav__title">Желания</h4>
          <Link to={appendCurrentRoute("/things")} className="wish">
            <div className="wish__container">
              <img
                className="wish__image"
                src={thingsIcon}
                alt="Иконка вещей"
              />
              <p className="wish__counter">{countItemsInSection("things")}</p>
            </div>
            <p className="wish__title">Вещи</p>
          </Link>
          <Link to={appendCurrentRoute("/places")} className="wish">
            <div className="wish__container">
              <img className="wish__image" src={placesIcon} alt="Иконка мест" />
              <p className="wish__counter">{countItemsInSection("places")}</p>
            </div>
            <p className="wish__title">Места</p>
          </Link>
          <Link to={appendCurrentRoute("/activities")} className="wish">
            <div className="wish__container">
              <img
                className="wish__image"
                src={activitiesIcon}
                alt="Иконка мероприятий"
              />
              <p className="wish__counter">
                {countItemsInSection("activities")}
              </p>
            </div>
            <p className="wish__title">События</p>
          </Link>
          <Link to={appendCurrentRoute("/travel")} className="wish">
            <div className="wish__container">
              <img
                className="wish__image"
                src={turismIcon}
                alt="Иконка путешествий"
              />
              <p className="wish__counter">{countItemsInSection("turism")}</p>
            </div>
            <p className="wish__title">Туризм</p>
          </Link>
        </div>
        <div className="nav" onClick={() => navigateTo("/friends")}>
          <h4 className="nav__title">
            Друзья
            <p className="nav__title-counter">{friends.length}</p>
          </h4>
          {friends.slice(0, 4).map((friend) => (
            <Link
              to={`/${friend.id}`}
              className="pal"
              key={friend.id}
              onClick={(e) => e.stopPropagation()}
            >
              <img
                className="pal__image"
                src={friend.image}
                alt="Фотография пользователя"
              />
              <p className="pal__title">{friend.name}</p>
            </Link>
          ))}
        </div>
      </div>
      <div className="nav" onClick={() => navigateTo("/subscriptions")}>
        <h4 className="nav__title">
          Подписки
          <p className="nav__title-counter">{subs.length}</p>
        </h4>
        {subs.slice(0, 4).map((sub) => (
          <Link
            to={`/brands/${sub.path}`}
            className="sub"
            key={sub.id}
            onClick={(e) => e.stopPropagation()}
          >
            <img
              className="sub__image"
              src={sub.image}
              alt="Фотография пользователя"
            />
            <div className="sub__container">
              <p className="sub__title">{sub.name}</p>
              <p className="sub__description">{sub.description}</p>
            </div>
          </Link>
        ))}
      </div>
      <Calendar />
    </section>
  );
}

export default UserNav;
