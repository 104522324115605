import "./Social.css";
import React from "react";
import { Link } from "react-router-dom";

function Social({ persons, title }) {
  return (
    <section className="social">
      <h4 className="social__title">
        {title}
        <p className="social__title-counter">{persons.length}</p>
      </h4>
      <div className="persons">
        {persons.map((person) => (
          <article className="person" key={person.id}>
            <img
              className={`person__image ${
                person.path ? "person__image_type_brand" : ""
              }`}
              src={person.image}
              alt="Фотография пользователя"
            />
            <div className="person__container">
              <Link
                to={person.path ? `/brands/${person.path}` : `/${person.id}`}
                className="person__title"
              >
                {person.name} {person.surname && person.surname}
              </Link>
              <Link
                to={person.path ? `/brands/${person.path}` : `/${person.id}`}
                className="person__link"
              >
                Открыть профиль
              </Link>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
}

export default Social;
