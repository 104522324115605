import React, { useState, useEffect, useRef } from "react";
import "./Slider.css";

import jelaemBanner from "../../images/Banners/jelaem.png";
import jelaemSmallBanner from "../../images/Banners/banner-small.png";

import useWindowSize from "../../hooks/useWindowSize";

function Slider() {
  const size = useWindowSize();

  const [banners, setBanners] = useState([
    {
      image: jelaemBanner,
      imageSmall: jelaemSmallBanner,
      link: "https://example.com/page1",
    },
    {
      image: jelaemBanner,
      imageSmall: jelaemSmallBanner,
      link: "https://example.com/page2",
    },
    {
      image: jelaemBanner,
      imageSmall: jelaemSmallBanner,
      link: "https://example.com/page3",
    },
  ]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState("right");
  const [isPaused, setIsPaused] = useState(false);
  const pauseTimeoutRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setDirection("right");
        setCurrentSlide((prevSlide) => (prevSlide + 1) % banners.length);
      }
    }, 5000); // Менять слайд каждые 5 секунд

    return () => clearInterval(interval); // Очистка интервала при размонтировании компонента
  }, [banners.length, isPaused]);

  const handleButtonClick = (index) => {
    setDirection(index > currentSlide ? "right" : "left");
    setCurrentSlide(index);

    // Очистка предыдущего таймера
    if (pauseTimeoutRef.current) {
      clearTimeout(pauseTimeoutRef.current);
    }

    // Установка паузы
    setIsPaused(true);
    pauseTimeoutRef.current = setTimeout(() => {
      setIsPaused(false);
    }, 10000); // Пауза 10 секунд
  };

  const getTransform = () => {
    const offset = currentSlide * 100 + ((currentSlide * 20) / 362) * 100; // Учитываем отступ между слайдами
    return `translateX(-${offset}%)`;
  };

  return (
    <div className="slider">
      <div className="slider__ad" style={{ transform: getTransform() }}>
        {banners.map((banner, index) => (
          <a
            key={index}
            className="slider__link"
            href={banner.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="slider__image"
              src={size.width <= 450 ? banner.imageSmall : banner.image}
              alt={`Banner ${index + 1}`}
            />
          </a>
        ))}
      </div>
      <p className="slider__ad-title">Реклама</p>
      <div className="slider__buttons">
        {banners.map((_, index) => (
          <button
            key={index}
            className={`slider__button ${
              index === currentSlide ? "slider__button--active" : ""
            }`}
            onClick={() => handleButtonClick(index)}
          ></button>
        ))}
      </div>
    </div>
  );
}

export default Slider;
