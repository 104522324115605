import Brands from "../Brands/Brands";
import Slider from "../Slider/Slider";
import "./Tips.css";
import React from "react";

import sidebarIcon from "../../images/Sidebar/Close-button.svg";

function Tips({ toggleSidebar }) {
  return (
    <section className="tips">
      <div className="caption caption_header">
        <h2 className="caption__title">Рекомендации</h2>
        <button className="sidebar-button" onClick={toggleSidebar}>
          <img
            src={sidebarIcon}
            className="sidebar-button__image"
            alt="Иконка сайдбара"
          />
        </button>
      </div>
      <div className="tips__container">
        <Brands />
        <Slider />
      </div>
    </section>
  );
}

export default Tips;
