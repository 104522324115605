import React, { useRef } from "react";
import dropdownOpenIcon from "../../images/input/dropdownIcon.svg";
import dropdownClosedIcon from "../../images/input/dropdownIconClose.svg";

const DropdownInput = ({
  name,
  value,
  options,
  onToggle,
  onSelect,
  dropdownState,
  isInputsDisabled,
  placeholder,
  dropdownRef,
}) => {
  const inputRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    inputRef.current.focus();
  };

  return (
    <div className="inputs-container">
      <p className={`input__title ${value ? "input__title_active" : ""}`}>
        {placeholder}
      </p>
      <div className="input-container" ref={dropdownRef}>
        <input
          className="input input_type_list"
          ref={inputRef}
          name={name}
          type="text"
          value={value || ""}
          onClick={onToggle}
          readOnly
          placeholder={placeholder}
          disabled={isInputsDisabled}
        />
        <img
          className="input__image"
          data-ref={name}
          onClick={onToggle}
          onMouseDown={handleMouseDown}
          src={dropdownState ? dropdownOpenIcon : dropdownClosedIcon}
          alt="Переключить список"
        />
        <div className="dropdown-container">
          <ul
            className={`dropdown-menu ${
              dropdownState ? "dropdown-menu_active" : ""
            } ${name === "category" ? "dropdown-menu_overflow" : ""}`}
          >
            {options.map((option, index) => (
              <li
                key={index}
                className="dropdown-menu__item"
                onClick={() => onSelect(option)}
              >
                {dropdownState && option}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DropdownInput;
