import "./Welcome.css";
import React from "react";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import welcomePhoto from "../../images/Welcome/welcome.svg";

function Welcome() {
  return (
    <div className="welcome">
      <Logo />
      <div className="welcome__container">
        <h1 className="welcome__title">
          Путь к вашим желаниям начинается здесь
        </h1>
        <h2 className="welcome__subtitle">
          Ваша мечта только в одном шаге от вас — сделайте его с нами!
        </h2>
      </div>
      <Link to="/about" className="welcome__link">
        {" "}
        Узнать больше
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.3444 13.3044C9.47563 13.3044 9.59591 13.2552 9.68886 13.1623L14.402 8.44915C14.5933 8.25778 14.5933 7.95159 14.402 7.76022L9.6834 3.05258C9.49203 2.86122 9.18584 2.86122 8.99447 3.05258C8.8031 3.24395 8.8031 3.55014 8.99447 3.74151L12.8765 7.62353H1.94121C1.6733 7.62353 1.45459 7.84224 1.45459 8.11015C1.45459 8.37807 1.6733 8.59677 1.94121 8.59677H12.882L12.8492 8.62958L8.99994 12.4788C8.90699 12.5718 8.85778 12.692 8.85778 12.8233C8.85778 12.9545 8.90699 13.0748 8.99994 13.1677C9.09289 13.2607 9.21318 13.3099 9.3444 13.3099V13.3044Z"
            fill="white"
          />
        </svg>
      </Link>
      <img
        src={welcomePhoto}
        className="welcome__image"
        alt="Изображение вещей"
      />
    </div>
  );
}

export default Welcome;
