import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./CardList.css";

import searchIcon from "../../images/Search/search-icon.svg";
import clearIcon from "../../images/Search/clear-icon.svg";
import filterIcon from "../../images/Search/fillter-icon.svg";

import thingsIcon from "../../images/wishs/things.svg";
import placesIcon from "../../images/wishs/places.svg";
import activitiesIcon from "../../images/wishs/activities.svg";
import turismIcon from "../../images/wishs/travel.svg";

function CardList({ onClickCard, cards, categories }) {
  const location = useLocation();
  const [cardsData, setCardsData] = useState(cards);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterSection, setFilterSection] = useState(null);
  const [filterCategories, setFilterCategories] = useState([]);
  const [activeFilterSection, setActiveFilterSection] = useState(null);
  const [activeFilterCategories, setActiveFilterCategories] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState(3);

  useEffect(() => {
    const filterByPath = () => {
      if (location.pathname.endsWith("/things"))
        return cards.filter((card) => card.section === "things");
      if (location.pathname.endsWith("/places"))
        return cards.filter((card) => card.section === "places");
      if (location.pathname.endsWith("/activities"))
        return cards.filter((card) => card.section === "activities");
      if (location.pathname.endsWith("/travel"))
        return cards.filter((card) => card.section === "turism");
      return cards;
    };

    setCardsData(filterByPath());
  }, [location.pathname, cards]);

  const applyFilters = () => {
    setFilterSection(activeFilterSection);
    setFilterCategories(activeFilterCategories);
    setIsFilterActive(false);
    setIsFilterApplied(true);
  };

  const clearFilters = () => {
    setActiveFilterSection(null);
    setActiveFilterCategories([]);
    setIsFilterApplied(false);
    setFilterSection(null);
    setFilterCategories([]);
    setIsFilterActive(false);
    setVisibleCategories(3);
  };

  const toggleSection = (section) => {
    setActiveFilterSection((prevSection) =>
      prevSection === section ? null : section
    );
    setActiveFilterCategories([]); // Очищать выбранные категории при выборе нового раздела
    setVisibleCategories(3);
  };

  const toggleCategory = (category) => {
    setActiveFilterCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((cat) => cat !== category)
        : [...prevCategories, category]
    );
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setSearchQuery(event.target.value);
    }
  };

  const loadMoreCategories = () => {
    setVisibleCategories((prev) => prev + 3);
  };

  const filteredCards = cardsData.filter((card) => {
    const matchesSearch =
      card.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      card.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesSection = !filterSection || card.section === filterSection;
    const matchesCategory =
      filterCategories.length === 0 || filterCategories.includes(card.category);

    return matchesSearch && matchesSection && matchesCategory;
  });

  const renderCard = (card) => {
    switch (card.section) {
      case "things":
        return (
          <div key={card.id} className="card" onClick={() => onClickCard(card)}>
            <div className="card__type">
              {card.tags && (
                <div className="cards__tags">
                  {card.tags.map((tag, index) => (
                    <p key={`${tag}-${index}`} className="card__tag">
                      {tag}
                    </p>
                  ))}
                </div>
              )}
              {card.list && (
                <div className="cards__tags">
                  <p className="card__tag">{card.list.name}</p>
                </div>
              )}
              <div className="card__section">
                <img
                  className="card__section-icon"
                  src={thingsIcon}
                  alt="Иконка вещей"
                />
              </div>
            </div>
            <h4 className="card__category">{card.category}</h4>
            <div className="card__container">
              <img className="card__image" src={card.image} alt={card.title} />
              <h3 className="card__title">{card.title}</h3>
              <p className="card__text">{card.price} ₽</p>
            </div>
          </div>
        );
      case "places":
        return (
          <div key={card.id} className="card" onClick={() => onClickCard(card)}>
            <div className="card__type">
              {card.tags && (
                <div className="cards__tags">
                  {card.tags.map((tag, index) => (
                    <p key={`${tag}-${index}`} className="card__tag">
                      {tag}
                    </p>
                  ))}
                </div>
              )}
              {card.list && (
                <div className="cards__tags">
                  <p className="card__tag">{card.list.name}</p>
                </div>
              )}
              <div className="card__section">
                <img
                  className="card__section-icon"
                  src={placesIcon}
                  alt="Иконка мест"
                />
              </div>
            </div>
            <h4 className="card__category">{card.category}</h4>
            <div className="card__container">
              <h3 className="card__title">{card.title}</h3>
              <p className="card__text">{card.description}</p>
              <img className="card__image" src={card.image} alt={card.title} />
            </div>
          </div>
        );
      case "activities":
        return (
          <div key={card.id} className="card" onClick={() => onClickCard(card)}>
            <div className="card__type">
              {card.tags && (
                <div className="cards__tags">
                  {card.tags.map((tag, index) => (
                    <p key={`${tag}-${index}`} className="card__tag">
                      {tag}
                    </p>
                  ))}
                </div>
              )}
              {card.list && (
                <div className="cards__tags">
                  <p className="card__tag">{card.list.name}</p>
                </div>
              )}
              <div className="card__section">
                <img
                  className="card__section-icon"
                  src={activitiesIcon}
                  alt="Иконка событий"
                />
              </div>
            </div>
            <h4 className="card__category">{card.category}</h4>
            <div className="card__container">
              <h3 className="card__title">{card.title}</h3>
              <p className="card__text">{card.description}</p>
              <div className="card__image-container">
                <img
                  className="card__image"
                  src={card.image}
                  alt={card.title}
                />
                <p className="card__date">{card.date}</p>
                {card.time && <p className="card__time">{card.time}</p>}
              </div>
            </div>
          </div>
        );
      case "turism":
        return (
          <div key={card.id} className="card" onClick={() => onClickCard(card)}>
            <div className="card__type">
              {card.tags && (
                <div className="cards__tags">
                  {card.tags.map((tag, index) => (
                    <p key={`${tag}-${index}`} className="card__tag">
                      {tag}
                    </p>
                  ))}
                </div>
              )}
              {card.list && (
                <div className="cards__tags">
                  <p className="card__tag">{card.list.name}</p>
                </div>
              )}
              <div className="card__section">
                <img
                  className="card__section-icon"
                  src={turismIcon}
                  alt="Иконка туризма"
                />
              </div>
            </div>
            <h4 className="card__category">{card.category}</h4>
            <div className="card__container">
              <h3 className="card__title">{card.title}</h3>
              <div className="card__image-container">
                <img
                  className="card__image"
                  src={card.image}
                  alt={card.title}
                />
                <img
                  className="card__flag"
                  src={card.flag}
                  alt={card.country}
                />
              </div>
              <p className="card__text card__text_type_travel">
                {card.country}
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.querySelector(".fillter").contains(event.target)) {
        setIsFilterActive(false);
      }
    };
    if (isFilterActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFilterActive]);

  useEffect(() => {
    if (isFilterActive) {
      const filterElement = document.querySelector(".fillter");
      const filterRect = filterElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (filterRect.bottom > viewportHeight) {
        window.scrollTo({
          top: filterRect.bottom - viewportHeight + 100,
          behavior: "smooth",
        });
      }
    }
  }, [isFilterActive]);

  return (
    <section className="card-list">
      <div className="caption">
        <h2 className="caption__title">Ваши рекомендации</h2>
        <div className={`search ${isFilterActive ? "search_active" : ""}`}>
          <button
            className="search__button"
            onClick={() =>
              setSearchQuery(document.querySelector(".search__input").value)
            }
          >
            <img
              className="search__icon"
              src={searchIcon}
              alt="Иконка поиска"
            />
          </button>
          <input
            className="search__input"
            type="text"
            placeholder="Поиск"
            onKeyDown={handleSearch} // Обработка нажатия клавиши Enter
          />
          <button
            className="search__button"
            onClick={() => {
              setSearchQuery("");
              document.querySelector(".search__input").value = "";
            }}
          >
            <img
              className="search__button-image"
              src={clearIcon}
              alt="Иконка очистить"
            />
          </button>
          <hr className="search__line" />
          <button
            className="search__button"
            onClick={() => setIsFilterActive(!isFilterActive)}
          >
            <img
              className={`search__button-image ${
                isFilterApplied ? "search__button-image_active" : ""
              }`}
              src={filterIcon}
              alt="Иконка фильтра"
            />
          </button>
          <div className={`fillter ${isFilterActive ? "fillter_active" : ""}`}>
            <p className="fillter__title">Раздел</p>
            <div className="sections">
              <button
                className={`section ${
                  activeFilterSection === "things" ? "section__active" : ""
                }`}
                onClick={() => toggleSection("things")}
              >
                <img
                  className={`section__image ${
                    activeFilterSection === "things"
                      ? "section__image_active"
                      : ""
                  }`}
                  src={thingsIcon}
                  alt="Иконка вещей"
                />
                <p
                  className={`section__title ${
                    activeFilterSection === "things"
                      ? "section__title_active"
                      : ""
                  }`}
                >
                  Вещи
                </p>
              </button>
              <button
                className={`section ${
                  activeFilterSection === "places" ? "section__active" : ""
                }`}
                onClick={() => toggleSection("places")}
              >
                <img
                  className={`section__image ${
                    activeFilterSection === "places"
                      ? "section__image_active"
                      : ""
                  }`}
                  src={placesIcon}
                  alt="Иконка мест"
                />
                <p
                  className={`section__title ${
                    activeFilterSection === "places"
                      ? "section__title_active"
                      : ""
                  }`}
                >
                  Места
                </p>
              </button>
              <button
                className={`section ${
                  activeFilterSection === "activities" ? "section__active" : ""
                }`}
                onClick={() => toggleSection("activities")}
              >
                <img
                  className={`section__image ${
                    activeFilterSection === "activities"
                      ? "section__image_active"
                      : ""
                  }`}
                  src={activitiesIcon}
                  alt="Иконка событий"
                />
                <p
                  className={`section__title ${
                    activeFilterSection === "activities"
                      ? "section__title_active"
                      : ""
                  }`}
                >
                  Мероприятия
                </p>
              </button>
              <button
                className={`section ${
                  activeFilterSection === "turism" ? "section__active" : ""
                }`}
                onClick={() => toggleSection("turism")}
              >
                <img
                  className={`section__image ${
                    activeFilterSection === "turism"
                      ? "section__image_active"
                      : ""
                  }`}
                  src={turismIcon}
                  alt="Иконка туризма"
                />
                <p
                  className={`section__title ${
                    activeFilterSection === "turism"
                      ? "section__title_active"
                      : ""
                  }`}
                >
                  Туризм
                </p>
              </button>
            </div>
            {activeFilterSection && (
              <>
                <p className="fillter__title">Категории</p>
                <div
                  className={`fillter__categories ${
                    isFilterActive ? "fillter__categories_active" : ""
                  }`}
                >
                  {categories[activeFilterSection] &&
                    categories[activeFilterSection]
                      .slice(0, visibleCategories)
                      .map((category) => (
                        <button
                          key={category}
                          className={`fillter__category ${
                            activeFilterCategories.includes(category)
                              ? "fillter__category_active"
                              : ""
                          }`}
                          onClick={() => toggleCategory(category)}
                        >
                          {category}
                        </button>
                      ))}
                  {categories[activeFilterSection] &&
                    visibleCategories <
                      categories[activeFilterSection].length && (
                      <button
                        className="fillter__load-more"
                        onClick={loadMoreCategories}
                      >
                        Еще...
                      </button>
                    )}
                </div>
              </>
            )}
            <div className="fillter__footer">
              <button className="fillter__clear-button" onClick={clearFilters}>
                Очистить
              </button>
              <button className="fillter__apply-button" onClick={applyFilters}>
                Применить
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="cards">
        {filteredCards.map((card) => renderCard(card))}
      </div>
    </section>
  );
}

export default CardList;
