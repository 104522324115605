import "./WishNav.css";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import thingsIcon from "../../images/wishs/things.svg";
import placesIcon from "../../images/wishs/places.svg";
import activitiesIcon from "../../images/wishs/activities.svg";
import turismIcon from "../../images/wishs/travel.svg";

import addIcon from "../../images/wish-nav/add-icon.svg";
import secretIcon from "../../images/wish-nav/secret-icon.svg";
import giftIcon from "../../images/wish-nav/gift.svg";

import useWindowSize from "../../hooks/useWindowSize";

function WishNav({ type, wishs, lists, handleAddClick }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeList, setActiveList] = useState(null);

  const { width } = useWindowSize();

  const getIconSrc = (type) => {
    switch (type) {
      case "things":
        return thingsIcon;
      case "places":
        return placesIcon;
      case "activities":
        return activitiesIcon;
      case "turism":
        return turismIcon;
      default:
        return "";
    }
  };

  const countWishsByType = (wishs, type) => {
    return wishs.filter((wish) => wish.section === type).length;
  };

  const getDeclension = (count) => {
    const cases = [2, 0, 1, 1, 1, 2];
    const titles = ["Желание", "Желания", "Желаний"];
    return titles[
      count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
    ];
  };

  const countItemsInSection = (section) => {
    return wishs.filter((card) => card.section === section).length;
  };

  const appendCurrentRoute = (path) => {
    const basePath = location.pathname.split("/").slice(0, -1).join("/");
    return `${basePath}${path}`;
  };

  const wishCount = countWishsByType(wishs, type);

  return (
    <section className="wish-nav">
      <div className="wish-nav__overlay">
        <div className="wish-nav__container">
          <div className="counter">
            <div className="counter__image-container">
              <img
                className="counter__image"
                src={getIconSrc(type)}
                alt={type}
              />
            </div>
            <div className="counter__container">
              <h4 className="counter__title">{wishCount}</h4>
              <p className="counter__subtitle">{`${getDeclension(
                wishCount
              )}`}</p>
            </div>
          </div>
          <button
            className="wish-nav__button"
            onClick={() => {
              handleAddClick(lists);
            }}
          >
            {width > 450 && "Добавить"}
            <img
              className="wish-nav__button-image"
              src={addIcon}
              alt="Иконка добавить"
            />
          </button>
        </div>
        <div className="nav nav_type_cursor nav_type_wish ">
          <h4 className="nav__title">Другие желания</h4>
          {type !== "things" && (
            <Link to={appendCurrentRoute("/things")} className="wish">
              <div className="wish__container">
                <img
                  className="wish__image"
                  src={thingsIcon}
                  alt="Иконка вещей"
                />
                <p className="wish__counter">{countItemsInSection("things")}</p>
              </div>
              <p className="wish__title">Вещи</p>
            </Link>
          )}
          {type !== "places" && (
            <Link to={appendCurrentRoute("/places")} className="wish">
              <div className="wish__container">
                <img
                  className="wish__image"
                  src={placesIcon}
                  alt="Иконка мест"
                />
                <p className="wish__counter">{countItemsInSection("places")}</p>
              </div>
              <p className="wish__title">Места</p>
            </Link>
          )}
          {type !== "activities" && (
            <Link to={appendCurrentRoute("/activities")} className="wish">
              <div className="wish__container">
                <img
                  className="wish__image"
                  src={activitiesIcon}
                  alt="Иконка мероприятий"
                />
                <p className="wish__counter">
                  {countItemsInSection("activities")}
                </p>
              </div>
              <p className="wish__title">События</p>
            </Link>
          )}
          {type !== "turism" && (
            <Link to={appendCurrentRoute("/travel")} className="wish">
              <div className="wish__container">
                <img
                  className="wish__image"
                  src={turismIcon}
                  alt="Иконка путешествий"
                />
                <p className="wish__counter">{countItemsInSection("turism")}</p>
              </div>
              <p className="wish__title">Туризм</p>
            </Link>
          )}
        </div>
        <div className="nav nav_type_cursor nav_type_list">
          <h4 className="nav__title">
            Списки
            <p className="nav__title-counter">{lists.length}</p>
          </h4>
          <div className="lists-small">
            {lists.map((list) => (
              <p
                key={list.id}
                className={`list-small ${
                  activeList === list.id ? "list-small_active" : ""
                }`}
                onClick={() => setActiveList(list.id)}
              >
                {list.name}
              </p>
            ))}
            <p className="list-small list-small_type_add">
              <img
                className="list-small__image"
                src={addIcon}
                alt="Иконка добавить"
              />
            </p>
          </div>
        </div>
        <div className="wish-nav__container">
          <div className="abs">
            <h4 className="abs__title">Секретные желания</h4>
            <img
              className="abs__image"
              src={secretIcon}
              alt="Иконка скрытого глаза"
            />
          </div>
          <div className="abs">
            <h4 className="abs__title">Хочу подарить</h4>
            <img
              className="abs__image"
              src={giftIcon}
              alt="Иконка скрытого глаза"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WishNav;
